import React from 'react';
import Checkbox from '../../../shared/Checkbox';

const Ques2 = ({ data, onChange }) => {

    return (
        <React.Fragment>
            <div className="text-xl font-semibold lg:text-3xl">Select these optional fields that you want in your resume?</div>
            
            <div className="mt-10">
                <div className="flex justify-between items-center my-6 w-full p-6 rounded-lg shadow-md hover:shadow-2xl hover:bg-gray-100 hover:font-bold" onClick={() => onChange('data.awards.enable', !data.awards.enable)}>
                    <span>Achievements</span>
                    <Checkbox
                        checked={data.awards.enable}
                        onChange={v => onChange('data.awards.enable', v)}
                        size="2rem"
                    />
                </div>
                <div className="flex justify-between items-center my-6 w-full p-6 rounded-lg shadow-md hover:shadow-2xl hover:bg-gray-100 hover:font-bold" onClick={() => onChange('data.projects.enable', !data.projects.enable)}>
                    <span>Projects</span>
                    <Checkbox
                        checked={data.projects.enable}
                        onChange={v => onChange('data.projects.enable', v)}
                        size="2rem"
                    />
                </div>
                <div className="flex justify-between items-center my-6 w-full p-6 rounded-lg shadow-md hover:shadow-2xl hover:bg-gray-100 hover:font-bold" onClick={() => onChange('data.internships.enable', !data.internships.enable)}>
                    <span>Internships</span>
                    <Checkbox
                        checked={data.internships.enable}
                        onChange={v => onChange('data.internships.enable', v)}
                        size="2rem"
                    />
                </div>
                <div className="flex justify-between items-center my-6 w-full p-6 rounded-lg shadow-md hover:shadow-2xl hover:bg-gray-100 hover:font-bold" onClick={() => onChange('data.hobbies.enable', !data.hobbies.enable)}>
                    <span>Hobbies</span>
                    <Checkbox
                        checked={data.hobbies.enable}
                        onChange={v => onChange('data.hobbies.enable', v)}
                        size="2rem"
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Ques2;