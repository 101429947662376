import React, { useContext } from 'react';

import AppContext from '../../context/AppContext';
import PageContext from '../../context/PageContext';
import image from '../../assets/images/CVKart-HD.png';
import Quiz from '../SkeletonQuiz/Ques';
import Footer from './Footer';

const Home = () => {

    const context = useContext(AppContext);
    const pageContext = useContext(PageContext);
    const { setAuthPreview, authPreview } = pageContext;
    const { state } = context;

    return (
        <div className={`${authPreview? 'overflow-hidden blurBackground' : null }`}>
            <div className="mx-8">
                <img src={image} alt='logo' className=" mt-10 flex-shrink-0 shadow-2xl rounded rounded-tr-full rounded-br-full pr-8 hover:shadow-xl" style={{ height: "8rem" }} />
                <div className="flex items-center lg:flex-col mt-10">
                    <div>
                        <p className="w-full font-semibold text-4xl lg:text-5xl">An Initiative From CVCORP To Help Freshers To Build Their Resume On Their Own.</p>
                        <p></p>
                    </div>
                    <a href="/resume" className="py-4 rounded-lg text-center text-white lg:text-4xl text-2xl my-10 shadow-2xl hover:shadow-xl w-full bg-teal-600 hover:bg-teal-500">Build Your Resume</a>
                </div>
                <hr className="mb-10" />
                <div className="mb-20 relative">
                    {
                        state.authState ?
                            null
                            :
                            <div className="absolute h-full w-full">
                                <button onClick={()=> setAuthPreview(true)} className="z-10 staticCenter border-teal-800 bg-teal-600 hover:bg-teal-500 border px-4 py-2 text-2xl lg:text-4xl text-white">LOGIN</button>
                            </div>                     
                    }
                    <div className={`flex justify-center ${state.authState? '' : 'blurBackground'}`}><Quiz /></div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home;