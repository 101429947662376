import React, { useContext } from 'react';
import linkedin from '../../assets/logo/linkedin.png';
import facebook from '../../assets/logo/facebook.png';
import instagram from '../../assets/logo/instagram.png';
import twitter from '../../assets/logo/twitter.png';
import linkedinDark from '../../assets/logo/linkedin-dark.png';
import facebookDark from '../../assets/logo/facebook-dark.png';
import instagramDark from '../../assets/logo/instagram-dark.png';
import twitterDark from '../../assets/logo/twitter-dark.png';
import AppContext from '../../context/AppContext';

const Footer = () => {

    const context = useContext(AppContext);
    const {state} = context;
    const darkMode = true;

    return(
        <div className={`w-full p-2 z-20 flex flex-col justify-center items-center shadow-xl ${darkMode ? "bg-teal-700 text-white shadow-inner" : "text-gray-900 bg-gray-200"}`}>
            <hr />

                <div className="font-bold my-4">Reach Us</div>

            <div className="flex justify-between items-center pb-6">

                <a href="https://www.linkedin.com/in/cvcorp"><img src={darkMode ? linkedinDark : linkedin} alt='linkedin' className="h-6 px-2"/></a>
                <a href="http://instagram.com/cvcorp"><img src={darkMode ? instagramDark : instagram} alt='instagram' className="h-6 px-2" style= {{fill: "white"}} /></a>
                <a href="https://www.twitter.com/cvcorpin"><img src={darkMode ? twitterDark : twitter} alt='twitter' className="h-6 px-2"/></a>
                <a href="https://www.facebook.com/cvcorpin"><img src={darkMode ? facebookDark : facebook} alt='facebook' className="h-6 px-2"/></a>

            </div>

            <div className="w-2/3 text-md pb-6 flex items-center justify-center"><span className="material-icons mr-2">location_on</span><span className="text-center">3rd Floor(Yathi Towers), KPHB Road No.1, HYD</span></div>

            <div>
                <hr className="py-2"/>
            <div className="flex justify-center items-center text-sm text-center pb-2">© Copyright 2020 CVCORP, Inc. All rights reserved. Various trademarks held by their respective owners.</div>
        </div>
        </div>
    )
}

export default Footer;