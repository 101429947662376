import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';

import TextField from '../../../shared/TextField';
import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import Counter from '../../../shared/Counter';
import { addItem } from '../../../utils';
import ItemActions from '../../../shared/ItemActions';
import AddItemButton from '../../../shared/AddItemButton';
import ItemHeading from '../../../shared/ItemHeading';

const LanguagesTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;

  useEffect(() => {
    if (!('languages' in data)) {
      dispatch({
        type: 'migrate_section',
        payload: {
          key: 'languages',
          value: {
            enable: false,
            heading: 'Languages',
            items: [],
          },
        },
      });

      dispatch({ type: 'save_data' });
    }
  }, [data, dispatch]);

  return (
    'languages' in data && (
      <>
        <div className="mb-6 grid grid-cols-6 items-center">
          <div className="col-span-1">
            <Checkbox
              checked={data.languages.enable}
              onChange={v => onChange('data.languages.enable', v)}
            />
          </div>
          <div className="col-span-5">
            <TextField
              placeholder="Heading"
              value={data.languages.heading}
              onChange={v => onChange('data.languages.heading', v)}
            />
          </div>
        </div>

        <hr className="my-6" />

        <div>

          {data.languages.items.length !== 0 ? <h1 className="lg:text-2xl" >Languages</h1> : null}
          {data.languages.items.map((x, index) => (
            <Item
              item={x}
              key={x.id}
              index={index}
              onChange={onChange}
              dispatch={dispatch}
              first={index === 0}
              last={index === data.languages.items.length - 1}
              label={"Language"}
              placeholder={"JAVA"}
              contextSection={"languages"}
            />
          ))}

          <AddItem heading={data.languages.heading} dispatch={dispatch} placeholder={"JAVA"}
            contextSection={"languages"} label={"Language"} />

        </div>

        <hr className="my-6" />

        <div>
          {data.database.items.length !== 0 ? <h1 className="lg:text-2xl" >Database</h1> : null}
          {data.database.items.map((x, index) => (
            <Item
              item={x}
              key={x.id}
              index={index}
              onChange={onChange}
              dispatch={dispatch}
              first={index === 0}
              last={index === data.database.items.length - 1}
              label={"Database"}
              placeholder={"SQL"}
              contextSection={"database"}
            />
          ))}
          <AddItem heading={data.languages.heading} dispatch={dispatch} label={"Database"}
            placeholder={"SQL"}
            contextSection={"database"} />
        </div>

        <hr className="my-6" />

        <div>
          {data.operatingSystem.items.length !== 0 ? <h1 className="lg:text-2xl" >Operating System</h1> : null}
          {data.operatingSystem.items.map((x, index) => (
            <Item
              item={x}
              key={x.id}
              index={index}
              onChange={onChange}
              dispatch={dispatch}
              first={index === 0}
              last={index === data.operatingSystem.items.length - 1}
              label={"Operating System"}
              placeholder={"Linux"}
              contextSection={"operatingSystem"}
            />
          ))}
          <AddItem heading={data.languages.heading} dispatch={dispatch} label={"Operating System"}
            placeholder={"Linux"}
            contextSection={"operatingSystem"} />
        </div>

        <hr className="my-6" />

        <div>
          {data.toolTechnologies.items.length !== 0 ? <h1 className="lg:text-2xl" >Tools & Technologies</h1> : null}
          {data.toolTechnologies.items.map((x, index) => (
            <Item
              item={x}
              key={x.id}
              index={index}
              onChange={onChange}
              dispatch={dispatch}
              first={index === 0}
              last={index === data.toolTechnologies.items.length - 1}
              label={"Tools/Technology"}
              placeholder={"VS"}
              contextSection={"toolTechnologies"}
            />
          ))}
          <AddItem heading={data.languages.heading} dispatch={dispatch} label={"Tools/Technology"}
            placeholder={"VS"}
            contextSection={"toolTechnologies"} />
        </div>

      </>
    )
  );
};


const Form = ({ item, onChange, label, placeholder, identifier = '' }) => {

  return (
    <div>
      <TextField
        className="mb-6"
        label={label}
        placeholder={placeholder}
        value={item.key === "XX" ? "" : item.key}
        onChange={v => onChange(`${identifier}key`, v)}
      />

      <Counter
        className="mb-6"
        label={"Rating"}
        value={item.value}
        onDecrement={() => item.value > 1 && onChange(`${identifier}value`, item.value - 1)}
        onIncrement={() => item.value < 5 && onChange(`${identifier}value`, item.value + 1)}
      />
    </div>
  );
};

const AddItem = ({ heading, dispatch, label, placeholder, contextSection }) => {
  const [isOpen, setOpen] = useState(false);
  const [item, setItem] = useState({
    id: uuidv4(),
    enable: true,
    key: '',
    value: 3,
  });

  const onChange = (key, value) => setItem(items => set({ ...items }, key, value));

  const onSubmit = () => {
    if (item.key === '') return;

    addItem(dispatch, contextSection, item);

    setItem({
      id: uuidv4(),
      enable: true,
      key: '',
      value: 3,
    });

    setOpen(false);
  };

  return (
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading heading={label} setOpen={setOpen} isOpen={isOpen} />

      <div className={`mt-6 ${isOpen ? 'block' : 'hidden'}`}>
        <Form item={item} onChange={onChange} label={label} placeholder={placeholder} />

        <AddItemButton onSubmit={onSubmit} />
      </div>
    </div>
  );
};

const Item = ({ item, index, onChange, dispatch, first, last, label, placeholder, contextSection }) => {
  const [isOpen, setOpen] = useState(false);
  const identifier = `data.${contextSection}.items[${index}].`;

  return (
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading title={item.key} setOpen={setOpen} isOpen={isOpen} />

      <div className={`mt-6 ${isOpen ? 'block' : 'hidden'}`}>
        <Form item={item} onChange={onChange} identifier={identifier} label={label} placeholder={placeholder} />

        <ItemActions
          dispatch={dispatch}
          first={first}
          identifier={identifier}
          item={item}
          last={last}
          onChange={onChange}
          type={contextSection}
        />
      </div>
    </div>
  );
};

export default LanguagesTab;
