
import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import AppContext from '../../context/AppContext';
import PageContext from '../../context/PageContext';
import firebase from '../../firestore/Firestore';

const User = () => {

    const pageContext = useContext(PageContext);
    const { profilePreview, setProfilePreview } = pageContext;
    const context = useContext(AppContext);
    const { state, dispatch } = context;
    const [email, setEmail] = useState('loading...');

    const db = firebase.firestore();

    const singOut = () => {
        dispatch({ type: 'reset' });
        dispatch({ type: 'save_data' });
        firebase.auth().signOut();
    }

    const saveChnages = () => {
        var user = firebase.auth().currentUser;
        var profilesArr = state.allProfiles;
        profilesArr[state.currentProfile] = state.data;
        if (user) {
            db.collection("resumeData").doc(user.uid).update({ profiles: profilesArr }).then(() => {
                toast(`Changes been saved to database `, {
                    bodyClassName: 'text-center text-gray-800 py-2 lg:text-2xl',
                })
            }).catch(() => {
                toast(`There seems to be some problem, please try again`, {
                    bodyClassName: 'text-center text-gray-800 py-2 lg:text-2xl',
                });
            })
        }
    }

    useEffect(() => {
        var user = firebase.auth().currentUser;
        if (user) {
            setEmail(user.email);
        }
    })

    return (
        <div>
            <div id="user" className="absolute right-0 bg-white rounded p-5 shadow-2xl lg:text-3xl">
                <div className="font-semibold">{email}</div>
                <hr className="my-4" />
                <div>
                    <button className="w-full text-left py-3 block font-medium hover:bg-gray-200 px-2 whitespace-no-wrap lg:text-3xl" onClick={() => { setProfilePreview(true) }}>Profile Manager</button>
                </div>
                <hr className="my-4" />
                <button onClick={saveChnages} className="w-full text-left py-3 block font-medium hover:bg-gray-200 px-2 whitespace-no-wrap lg:text-3xl">Save Changes</button>
                <hr className="my-2 w-2/3" />
                <button onClick={singOut} className="w-full text-left py-3 block font-medium hover:bg-gray-200 px-2 lg:text-3xl">Logout</button>
            </div>
        </div>
    )
}

export default User;