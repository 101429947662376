import React from 'react';

import TextField from '../../../shared/TextField';

const Ques1 = ({ data, onChange }) => {

    return (
        <React.Fragment>
            <div>
                <div className="text-xl font-semibold lg:text-3xl">Enter your name?</div>

                <div className="mt-4 flex">
                    <TextField
                        className="mt-2 inline mx-2"
                        label={'First Name'}
                        placeholder="Jane"
                        value={data.profile.firstName === "Your" ? "" : data.profile.firstName}
                        onChange={v => onChange('data.profile.firstName', v)}
                        tip={"Enter Your Full Name"}
                    />

                    <TextField
                        className="mt-2 inline mx-2"
                        label={'Last Name'}
                        placeholder="Doe"
                        value={data.profile.lastName === "Full Name" ? "" : data.profile.lastName}
                        onChange={v => onChange('data.profile.lastName', v)}
                    />
                </div>
            </div>
            <div className="mt-4">
                <div className="text-xl font-semibold lg:text-3xl">Enter your Contact No.?</div>

                <div className="mt-2">
                    <TextField
                        className="mt-4 mx-2"
                        placeholder="+91 00000 00000"
                        value={data.profile.phone === "+91 00000 00000" ? "" : data.profile.phone}
                        onChange={v => onChange('data.profile.phone', v)}
                    />
                </div>
            </div>
            <div className="mt-4">
                <div className="text-xl font-semibold lg:text-3xl">Enter your Email?</div>

                <div className="mt-2">
                    <TextField
                        className="mt-4 mx-2"
                        placeholder="jane.doe@example.com"
                        value={data.profile.email === "xxxxxxx@xxxx.com" ? "" : data.profile.email}
                        onChange={v => onChange('data.profile.email', v)}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Ques1;