import React, { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';

import TextField from '../../../shared/TextField';
import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import TextArea from '../../../shared/TextArea';
import { addItem } from '../../../utils';
import ItemActions from '../../../shared/ItemActions';
import ItemHeading from '../../../shared/ItemHeading';
import AddItemButton from '../../../shared/AddItemButton';

const dummy="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."

const Internship = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;

  return (
    <>
      <div className="my-6 grid grid-cols-6 items-center">
        <div className="col-span-1">
          <Checkbox
            checked={data.internships.enable}
            onChange={v => onChange('data.internships.enable', v)}
          />
        </div>
        <div className="col-span-5">
          <TextField
            placeholder="Heading"
            value={data.internships.heading}
            onChange={v => onChange('data.internships.heading', v)}
          />
        </div>
      </div>

      <hr className="my-6" />

      {data.internships.items.map((x, index) => (
        <Item
          item={x}
          key={x.id}
          index={index}
          onChange={onChange}
          dispatch={dispatch}
          first={index === 0}
          last={index === data.internships.items.length - 1}
        />
      ))}

      <AddItem heading={data.internships.heading} dispatch={dispatch} />
    </>
  );
};

const Form = ({ item, onChange, identifier = '' }) => {

  return (
      <div>
          <TextField
              className="mb-6"
              label={'Title'}
              value={item.title==="Internship Title 1"?"":item.title}
              onChange={v => onChange(`${identifier}title`, v)}
          />

          <TextField
              className="mb-6"
              label={'Company Name'}
              value={item.companyName==="Random.Inc"? "": item.companyName}
              onChange={v => onChange(`${identifier}companyName`, v)}
          />

          <TextField
              className="mb-6"
              label={'Role'}
              value={item.role==="XXXX"?"":item.role}
              onChange={v => onChange(`${identifier}role`, v)}
          />

          <TextArea
              className="mb-6"
              label={'Responsibilities'}
              value={item.responsibilities===dummy?"":item.responsibilities}
              onChange={v => onChange(`${identifier}responsibilities`, v)}
          />

          <TextArea
              className="mb-6"
              label={'Learnings'}
              value={item.learnings===dummy?"":item.learnings}
              onChange={v => onChange(`${identifier}learnings`, v)}
          />

          <TextField
              className="mb-6"
              label={'Tools & Technology Used'}
              value={item.toolsTech==="Tools, Tools2"? "": item.toolsTech}
              onChange={v => onChange(`${identifier}toolsTech`, v)}
          />

          <div className="grid grid-cols-2 col-gap-4">
              <TextField
                  className="mb-6"
                  label={'Start'}
                  value={item.start==="--- ****"? "": item.start}
                  onChange={v => onChange(`${identifier}start`, v)}
              />

              <TextField
                  className="mb-6"
                  label={'End'}
                  value={item.end==="--- ****"? "": item.end}
                  onChange={v => onChange(`${identifier}end`, v)}
              />
          </div>
      </div>
  );
};

const AddItem = ({ heading, dispatch }) => {
  const [isOpen, setOpen] = useState(false);
  const [item, setItem] = useState({
      id: uuidv4(),
      enable: true,
      title: '',
      companyName: '',
      toolsTech: '',
      learnings: '',
      start: '',
      end: '',
      role: '',
      responsibilities: '',
  });

  const onChange = (key, value) => setItem(set({ ...item }, key, value));

  const onSubmit = () => {
    if (item.title === '') return;

    addItem(dispatch, 'internships', item);

    setItem({
        id: uuidv4(),
        enable: true,
        title: '',
        companyName: '',
        toolsTech: '',
        learnings: '',
        start: '',
        end: '',
        role: '',
        responsibilities: '',
    });

    setOpen(false);
  };

  return (
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading heading={heading} setOpen={setOpen} isOpen={isOpen} />

      <div className={`mt-6 ${isOpen ? 'block' : 'hidden'}`}>
        <Form item={item} onChange={onChange} />

        <AddItemButton onSubmit={onSubmit} />
      </div>
    </div>
  );
};

const Item = ({ item, index, onChange, dispatch, first, last }) => {
  const [isOpen, setOpen] = useState(false);
  const identifier = `data.internships.items[${index}].`;

  return (
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading title={item.title} setOpen={setOpen} isOpen={isOpen} />

      <div className={`mt-6 ${isOpen ? 'block' : 'hidden'}`}>
        <Form item={item} onChange={onChange} identifier={identifier} />

        <ItemActions
          dispatch={dispatch}
          first={first}
          identifier={identifier}
          item={item}
          last={last}
          onChange={onChange}
          type="internships"
        />
      </div>
    </div>
  );
};

export default Internship;