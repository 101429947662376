import React, { useContext, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import AppContext from '../../context/AppContext';
import PageContext from '../../context/PageContext';

const Onyx = () => {
  const context = useContext(AppContext);
  const pageContext = useContext(PageContext)
  const { isPrintDialogOpen } = pageContext;
  const { state } = context;
  const { data, theme } = state;

  const parent = useRef(null)
  const section1 = useRef(null)
  const section2 = useRef(null)
  const section3 = useRef(null)
  const section4 = useRef(null)
  const section5 = useRef(null)
  const section6 = useRef(null)
  const section7 = useRef(null)
  const section8 = useRef(null)
  const section9 = useRef(null)
  const marginSection1 = useRef(null);
  const marginSection2 = useRef(null);
  const marginSection3 = useRef(null);
  const marginSection4 = useRef(null);
  const marginSection5 = useRef(null);
  const marginSection6 = useRef(null);

  //well if you are confused and thinking what the hell is this? well, i don't blame you .... 
  //this is my great effort to create partition between two pages of a pdf and it works suprisingly well
  //In case if you come with a better solution, you make sure you let me(Naveen Sharma) know. hej då!
  useEffect(() => {
    if (window.location.pathname === "/resume") {
      // let totalHeight = parent.current.clientHeight;
      let part1 = section1.current.clientHeight + section2.current.clientHeight;
      let part2 = part1 + section3.current.clientHeight;
      let part3 = part2 + section4.current.clientHeight;
      let part4 = part3 + section5.current.clientHeight;
      let part5 = part4 + section6.current.clientHeight;
      let part6 = part5 + section7.current.clientHeight;
      let part7 = part6 + section8.current.clientHeight;
      let part8 = part7 + section9.current.clientHeight;
      const pageHeight = 1164 - 43 - 30;

      marginSection1.current.style.marginBottom = '0px';
      marginSection2.current.style.marginBottom = '0px';
      marginSection3.current.style.marginBottom = '0px';
      marginSection4.current.style.marginBottom = '0px';
      marginSection5.current.style.marginBottom = '0px';
      marginSection6.current.style.marginBottom = '0px';

      if (isPrintDialogOpen) {
        if (part1 > pageHeight) {
          let mB = pageHeight - section1.current.clientHeight
        } else if (part2 > pageHeight) {
          let mB = pageHeight - part1
        } else if (part3 > pageHeight) {
          let mB = pageHeight - part2
          marginSection1.current.style.marginBottom = `${mB}px`;
        } else if (part4 > pageHeight) {
          let mB = pageHeight - part3
          marginSection2.current.style.marginBottom = `${mB}px`;
        } else if (part5 > pageHeight) {
          let mB = pageHeight - part4
          marginSection3.current.style.marginBottom = `${mB}px`;
        } else if (part6 > pageHeight) {
          let mB = pageHeight - part5
          marginSection4.current.style.marginBottom = `${mB}px`;
        } else if (part7 > pageHeight) {
          let mB = pageHeight - part6
          marginSection5.current.style.marginBottom = `${mB}px`;
        } else if (part8 > pageHeight) {
          let mB = pageHeight - part7
          marginSection6.current.style.marginBottom = `${mB}px`;
        }
      }
    }
  }, [isPrintDialogOpen===true])

  const Profile = () => (
    <div>
      <h1 className="font-bold text-4xl" style={{ color: theme.colors.accent }}>
        {data.profile.firstName} {data.profile.lastName}
      </h1>
      <h6 className="font-medium text-sm">{data.profile.subtitle}</h6>
    </div>
  );

  const ContactItem = ({ icon, value, link = '#' }) =>
    value && (
      <div className="flex items-center my-3">
        <span className="material-icons text-lg mr-2" style={{ color: theme.colors.accent }}>
          {icon}
        </span>
        <a href={link}>
          <span className="font-medium break-all">{value}</span>
        </a>
      </div>
    );

  const Heading = ({ title }) => (
    <h6 className="text-xs font-bold uppercase pt-6 pb-2" style={{ color: theme.colors.accent }}>
      {title}
    </h6>
  );

  const Objective = (ref) =>
    data.objective &&
    data.objective.enable && (
      <div>
        <Heading title={data.objective.heading} />
        <ReactMarkdown className="text-sm" source={data.objective.body} />
      </div>
    );

  const EducationItem = (x, index) => (
    <div key={index} className="mt-3">
      <div className="flex justify-between">
        <div>
          <h6 className="font-semibold">{x.name}</h6>
          <p className="text-xs">{x.major}</p>
        </div>
        <div className="flex flex-col items-end">
          <span className="text-sm font-bold">{x.grade}</span>
          <span className="text-xs font-medium">
            ({x.start} - {x.end})
          </span>
        </div>
      </div>
      <ReactMarkdown className="mt-2 text-sm" source={x.description} />
    </div>
  );

  const EducationSchool = (x, index) => {
    return (
      <div key={index} className="mt-3">
        <div className="flex justify-between">
          <div>
            <h6 className="font-semibold">{x.heading}</h6>
            <p className="text-xs">{`${x.schoolName} (${x.schoolBoard})`}</p>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-sm font-bold">{x.totalPercentage}</span>
            <span className="text-xs font-medium">
              ({x.startingYear} - {x.endYear})
          </span>
          </div>
        </div>
      </div>
    )
  }

  const EducationDegree = (x, index) => {
    return (
      <div key={index} className="mt-3">
        <div className="flex justify-between">
          <div>
            <h6 className="font-semibold">{x.degree} in {x.branch}</h6>
            <p className="text-xs">{`${x.collegeName}, ${x.university}`}</p>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-sm font-bold">{x.percentage}</span>
            <span className="text-xs font-medium">
              ({x.startingYear} - {x.endYear})
          </span>
          </div>
        </div>
      </div>
    )
  }

  const Education = () =>
    data.education &&
    data.education.enable && (
      <div>
        <Heading title={data.education.heading} />
        {data.education.items.filter((x) => x.enable).map(EducationItem)}
        {data.education.graduation.degree ? EducationDegree(data.education.graduation) : null}
        {data.education.twelveth.schoolName ? EducationSchool(data.education.twelveth) : null}
        {data.education.tenth.schoolName ? EducationSchool(data.education.tenth) : null}
      </div>
    );

  const ProjectItem = (x, index) => {
    return (
      <div key={index} className="mt-3">
        <div className="grid grid-cols-4">
          <div className="col-span-3">
            <h6 className="font-semibold">{x.title}</h6>
            {x.description !== "" ? <div><span className="text-sm font-semibold inline">Description: </span>
              <p className="text-sm inline">{x.description}</p></div> : null}
            {x.problemStatement !== "" ? <div><span className="text-sm font-semibold inline">Problem Statement: </span>
              <p className="text-sm inline">{x.problemStatement}</p></div> : null}
            {x.learnings !== "" ? <div><span className="text-sm font-semibold inline">learnings: </span>
              <p className="text-sm inline">{x.learnings}</p></div> : null}
            {x.toolsTech !== "" ? <div><span className="text-sm font-semibold inline">Tools & Techechnology Used: </span>
              <p className="text-sm inline" >{x.toolsTech}</p></div> : null}
          </div>
          <div className="col-span-1 flex flex-col items-end">
            <span className="text-xs font-medium">
              ({x.start} - {x.end})
          </span>
          </div>
        </div>
      </div>
    )
  }

  const Projects = () =>
    data.projects &&
    data.projects.enable && (
      <div>
        <Heading title={data.projects.heading} />
        {data.projects.items.filter((x) => x.enable).map(ProjectItem)}
      </div>
    )

  const InternshipItem = (x, index) => {
    return (
      <div key={index} className="mt-3">
        <div className="grid grid-cols-4">
          <div className="col-span-3">
            <h6 className="font-semibold">{x.companyName}</h6>
            {x.Role !== "" ? <div><span className="text-sm font-semibold inline">Role: </span>
              <span className="text-sm">{x.role}</span></div> : null}
            {x.responsibilities !== "" ? <div><span className="text-sm font-semibold inline">Responsibilities: </span>
              <p className="text-sm inline">{x.responsibilities} </p></div> : null}
            {x.learnings !== "" ? <div><span className="text-sm font-semibold inline">Learnings: </span>
              <p className="text-sm inline">{x.learnings} </p></div> : null}
            {x.toolsTech !== "" ? <div><span className="text-sm font-semibold inline">Tools & Techechnology Used: </span>
              <p className="text-sm inline" >{x.toolsTech}</p></div> : null}
          </div>
          <div className="col-span-1 flex flex-col items-end">
            <span className="text-xs font-medium">
              ({x.start} - {x.end})
            </span>
          </div>
        </div>
      </div>
    )
  }

  const Internships = () =>
    data.internships &&
    data.internships.enable && (
      <div>
        <Heading title={data.internships.heading} />
        {data.internships.items.filter((x) => x.enable).map(InternshipItem)}
      </div>
    )

  const AwardItem = (x, index) => (
    <div key={index} className="mt-3">
      <h6 className="font-semibold">{x.title}</h6>
      <p className="text-xs">{x.subtitle}</p>
      <ReactMarkdown className="mt-2 text-sm" source={x.description} />
    </div>
  );

  const Awards = () =>
    data.awards &&
    data.awards.enable && (
      <div>
        <Heading title={data.awards.heading} />
        <div className="grid grid-cols-2">
          {data.awards.items.filter((x) => x.enable).map(AwardItem)}
        </div>

      </div>
    );

  const CertificationItem = (x, index) => (
    <div key={index} className="mt-3">
      <h6 className="font-semibold">{x.title}</h6>
      <p className="text-xs">{x.subtitle}</p>
      <ReactMarkdown className="mt-2 text-sm" source={x.description} />
    </div>
  );

  const Certifications = () =>
    data.certifications &&
    data.certifications.enable && (
      <div>
        <Heading title={data.certifications.heading} />
        {data.certifications.items.filter((x) => x.enable).map(CertificationItem)}
      </div>
    );

  const SkillItem = (x, index) => (
    <span
      key={index}
      className="text-xs rounded-full px-3 py-1 font-medium my-2 mr-2"
      style={{
        backgroundColor: theme.colors.primary,
        color: theme.colors.background,
      }}
    >
      {x}
    </span>
  );

  const Skills = () =>
    data.skills &&
    data.skills.enable && (
      <div>
        <Heading title={data.skills.heading} />
        <div className="mt-1 flex flex-wrap">{data.skills.items.map(SkillItem)}</div>
      </div>
    );

  const HobbyItem = (x, index) => (
    <span
      key={index}
      className="text-xs rounded-full px-3 py-1 font-medium my-2 mr-2"
      style={{
        backgroundColor: theme.colors.primary,
        color: theme.colors.background,
      }}
    >
      {x}
    </span>
  );

  const Hobbies = () =>
    data.hobbies &&
    data.hobbies.enable && (
      <div>
        <Heading title={data.hobbies.heading} />
        <div className="mt-1 flex flex-wrap">{data.hobbies.items.map(HobbyItem)}</div>
      </div>
    );

  const TechnicalSkillsItem = (x) => (
    <div key={x.id} className="grid grid-cols-2 items-center py-2">
      <h6 className="text-sm font-medium">{x.key}</h6>
      <div className="flex">
        {Array.from(Array(x.value)).map((_, i) => (
          <i key={i} className="material-icons text-lg" style={{ color: theme.colors.accent }}>
            star
          </i>
        ))}
      </div>
    </div>
  );

  const TechnicalSkills = () =>
    data.languages &&
    data.languages.enable && (
      <div>
        <Heading title={data.languages.heading} />
        <div className="grid grid-cols-4 gap-16">
          {data.languages.items.length !== 0 ? <div className="">
            <h6 className="text-sm font-semibold my-2">Languages</h6>
            {data.languages.items.filter((x) => x.enable).map(TechnicalSkillsItem)}
          </div> : null}
          {data.database.items.length !== 0 ? <div className="">
            <h6 className="text-sm font-semibold my-2">Database</h6>
            {data.database.items.filter((x) => x.enable).map(TechnicalSkillsItem)}
          </div> : null}
          {data.toolTechnologies.items.length !== 0 ? <div className="">
            <h6 className="text-sm font-semibold my-2">Tools & Technologies</h6>
            {data.toolTechnologies.items.filter((x) => x.enable).map(TechnicalSkillsItem)}
          </div> : null}
          {data.operatingSystem.items.length !== 0 ? <div className="">
            <h6 className="text-sm font-semibold my-2">Operating System</h6>
            {data.operatingSystem.items.filter((x) => x.enable).map(TechnicalSkillsItem)}
          </div> : null}
        </div>
      </div>
    );

  const ExtraItem = (x) => (
    <div key={x.id} className="">
      <span className="text-xs font-bold">{x.key + ": "}</span>
      <span className="text-sm">{x.value}</span>
    </div>
  );

  const Extras = () =>
    data.extras &&
    data.extras.enable && (
      <div>
        <Heading title={data.extras.heading} />
        <div className="ml-4">
          {data.extras.items.filter(x => x.enable).map(ExtraItem)}
          {data.profile.address.line1 === "" ? null : <div>
            <span className="text-xs font-bold">Address: </span>
            <span className="text-sm">{`${data.profile.address.line1} ${data.profile.address.line2} ${data.profile.address.line3}`}</span>
          </div>}
        </div>
      </div>
    );

  return (
    <div
      ref={parent}
      className="p-10"
      style={{
        fontFamily: theme.font.family,
        backgroundColor: theme.colors.background,
        color: theme.colors.primary,
      }}
    >
      <div className="grid grid-cols-4 items-center" ref={section1}>
        <div className="col-span-3 flex items-center">
          <Profile />
        </div>

        <div className="col-span-1 text-xs">
          <ContactItem icon="phone" value={data.profile.phone} link={`tel:${data.profile.phone}`} />
          <ContactItem
            icon="email"
            value={data.profile.email}
            link={`${data.profile.email}`}
          />
          <ContactItem
            icon="language"
            value={data.profile.website}
            link={`${data.profile.website}`}
          />
          <ContactItem
            icon="location_on"
            value={data.profile.currentLocation}
            link={`${data.profile.currentLocation}`}
          />
        </div>
      </div>

      <hr className="my-6" />

      <div ref={section2}><Objective /></div>
      <div ref={section3}><Education /></div>
      <div ref={marginSection1}></div>
      <div ref={section4}><TechnicalSkills /></div>
      <div ref={marginSection2}></div>
      <div ref={section5}><Projects /></div>
      <div ref={marginSection3}></div>
      <div ref={section6}><Internships /></div>

      <div ref={marginSection4}></div>

      <div ref={section7}>
        <Awards />
        {/* <Certifications /> */}
      </div>

      <div ref={marginSection5}></div>

      <div className="grid grid-cols-2 gap-6" ref={section8}>
        <Skills />
        <Hobbies />
      </div>

      <div ref={marginSection6}></div>

      <div ref={section9}><Extras /></div>
    </div>
  );
};

export default Onyx;
