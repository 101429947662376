import React from 'react';

import TextArea from '../../../shared/TextArea';
import TextField from '../../../shared/TextField';
import Checkbox from '../../../shared/Checkbox';
import Dropdown from '../../../shared/Dropdown';

const ObjectiveTab = ({ data, onChange }) => {

  return (
    <div>
      <div className="mb-6 grid grid-cols-6 items-center">
        <div className="col-span-1">
          <Checkbox
            checked={data.objective.enable}
            onChange={v => onChange('data.objective.enable', v)}
          /> 
        </div>

        <div className="col-span-5">
          <TextField
            placeholder="Heading"
            value={data.objective.heading}
            onChange={v => onChange('data.objective.heading', v)}
          />
        </div>
      </div>

      <Dropdown
          className='mb-6'
          label={'Career Objective'}
          value={data.careerObjective}
          onChange={x => onChange('data.careerObjective', x)}
          options={["Job Search","Internship","Higher Education"]}
          optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
          )}
        />

        {data.careerObjective==="Job Search"?<Dropdown
          className='mb-6'
          label={'At what level are you?'}
          value={data.job}
          onChange={x => onChange('data.job', x)}
          options={['Trained Fresher', 'Recent Passout', 'Experienced Profile']}
          optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
          )}
        />:null}

      <hr className="my-6" />

      <TextArea
        rows="15"
        className="mb-4"
        label={'Objective'}
        value={data.objective.body === "XXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX xxxxxxxxxx XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXX" ?
          "" : data.objective.body}
        placeholder="Looking for a challenging role in a reputable organization to utilize my technical, database, and management skills for the growth of the organization as well as to enhance my knowledge about new and emerging trends in the IT sector."
        onChange={v => onChange('data.objective.body', v)}
      />
    </div>
  );
};

export default ObjectiveTab;
