import React, { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';

import TextField from '../../../shared/TextField';
import TextArea from '../../../shared/TextArea';
import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import { addItem } from '../../../utils';
import ItemActions from '../../../shared/ItemActions';
import AddItemButton from '../../../shared/AddItemButton';
import ItemHeading from '../../../shared/ItemHeading';
import Dropdown from '../../../shared/Dropdown';

const EducationTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;

  return (
    <>
      <div className="mb-6 grid grid-cols-6 items-center">
        <div className="col-span-1">
          <Checkbox
            checked={data.education.enable}
            onChange={v => onChange('data.education.enable', v)}
          />
        </div>
        <div className="col-span-5">
          <TextField
            placeholder="Heading"
            value={data.education.heading}
            onChange={v => onChange('data.education.heading', v)}
          />
        </div>
      </div>

      <hr className="my-6" />

      <TenthClass onChange={onChange} data={data} heading={data.education.tenth.heading}/>

      <TwelvethClass onChange={onChange} data={data} heading={data.education.twelveth.heading}/>

      <Graduation onChange={onChange} data={data} />

      {data.education.items.map((x, index) => (
        <Item
          item={x}
          key={x.id}
          index={index}
          onChange={onChange}
          dispatch={dispatch}
          first={index === 0}
          last={index === data.education.items.length - 1}
        />
      ))}

      <AddItem heading={data.education.heading} dispatch={dispatch} />
    </>
  );
};

const Graduation = ({onChange, data}) => {
  const[show, setShow]=useState(false);
  return(
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading title={'Graduation'} setOpen={setShow} isOpen={show} />
      <div className={`mt-6 ${show ? 'block' : 'hidden'}`}>
        <GraduationForm onChange={onChange} data={data} />
      </div>
    </div>
  )
}

const GraduationForm = ({onChange, data}) => {
  return(
    <div>
      {/* <Dropdown
        className='mb-6'
        label={('Course')}
        value={data.education.graduation.degree}
        onChange={x => onChange('data.education.graduation.degree', x)}
        options={["B.TECH","B.E"]}
        optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
        )}
      /> */}

      {/* <Dropdown
        className='mb-6'
        label={('Branch')}
        value={data.education.graduation.branch}
        onChange={x => onChange('data.education.graduation.branch', x)}
        options={["MECH","CSE","IT","CIVIL","EEE","ECE","OTHERS"]}
        optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
        )}
      /> */}

      <TextField
        className="mb-6"
        label={'Course'}
        placeholder="Type Here"
        value={data.education.graduation.degree}
        onChange={v => onChange(`data.education.graduation.degree`, v)}
      />

      {/* {data.education.graduation.branch==="OTHERS" ?  */}
      <TextField
        className="mb-6"
        label={('Specify Branch')}
        placeholder="Type Here"
        value={data.education.graduation.branch}
        onChange={v => onChange(`data.education.graduation.branch`, v)}
      /> 
       {/* : null} */}

      <TextField
        className="mb-6"
        label={('College Name')}
        placeholder="College Name"
        value={data.education.graduation.collegeName==="XXXXXX College"? "": data.education.graduation.collegeName}
        onChange={v => onChange(`data.education.graduation.collegeName`, v)}
      />

      <TextField
        className="mb-6"
        label={('University')}
        placeholder="University Name"
        value={data.education.graduation.university==="XXXXXX University"? "": data.education.graduation.university}
        onChange={v => onChange(`data.education.graduation.university`, v)}
      />

      <TextField
        className="mb-6"
        label={("Percentage/Grade")}
        placeholder="60%"
        value={data.education.graduation.percentage==="xx %"? "": data.education.graduation.percentage}
        onChange={v => onChange(`data.education.graduation.percentage`, v)}
      />

      <div className="grid grid-cols-2 col-gap-4">
        <TextField
          className="mb-6"
          label={'Starting Year'}
          placeholder="2015"
          value={data.education.graduation.startingYear==="MMM YYYY"? "": data.education.graduation.startingYear}
          onChange={v => onChange(`data.education.graduation.startingYear`, v)}
        />

        <TextField
          className="mb-6"
          label={'Ending Year'}
          placeholder="2019"
          value={data.education.graduation.endYear==="MMM YYYY"? "": data.education.graduation.endYear}
          onChange={v => onChange(`data.education.graduation.endYear`, v)}
        />
        {/* <Dropdown
          className='mb-6'
          label={('Starting Year')}
          value={data.education.graduation.startingYear}
          onChange={x => onChange('data.education.graduation.startingYear', x)}
          options={["2012","2013","2014","2015","2016","2017"]}
          optionItem={x => (
            <option key={x} value={x}>
              {x}
            </option>
          )}
        />

        <Dropdown
          className='mb-6'
          label={('Ending Year')}
          value={data.education.graduation.endYear}
          onChange={x => onChange('data.education.graduation.endYear', x)}
          options={["2016","2017","2018","2019","2020","2021"]}
          optionItem={x => (
            <option key={x} value={x}>
              {x}
            </option>
          )}
        /> */}
      </div>
    </div>
  )
}

const TenthClass = ({onChange, data, heading}) => {
  const[tenthShow, setTenthShow]=useState(false);
  return(
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading title={heading} setOpen={setTenthShow} isOpen={tenthShow} />
      <div className={`mt-6 ${tenthShow ? 'block' : 'hidden'}`}>
        <TenthClassForm onChange={onChange} data={data} />
      </div>
    </div>
  )
}

const TenthClassForm = ({onChange, data}) => {
  return(
    <div>

      <TextField
        className="mb-6"
        label={'Heading'}
        placeholder="High School"
        value={data.education.tenth.heading}
        onChange={v => onChange(`data.education.tenth.heading`, v)}
      />

      <Dropdown
        className='mb-6'
        label={'School Type'}
        value={data.education.tenth.schoolType}
        onChange={x => onChange('data.education.tenth.schoolType', x)}
        options={["Private","Government"]}
        optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
        )}
      />

      <TextField
        className="mb-6"
        label={'School Name'}
        placeholder="School Name"
        value={data.education.tenth.schoolName==="XXXXXX School, Town Name"? "":data.education.tenth.schoolName}
        onChange={v => onChange(`data.education.tenth.schoolName`, v)}
      />

      <Dropdown
        className='mb-6'
        label={'School Board'}
        value={data.education.tenth.schoolBoard}
        onChange={x => onChange('data.education.tenth.schoolBoard', x)}
        options={["CBSE","State Board","ICSE"]}
        optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
        )}
      />

      <TextField
        className="mb-6"
        label={'Percentage/Grade'}
        placeholder="60%"
        value={data.education.tenth.totalPercentage==="XX%"? "": data.education.tenth.totalPercentage}
        onChange={v => onChange(`data.education.tenth.totalPercentage`, v)}
      />

      <TextField
        className="mb-6"
        label={'Math Percentage/marks'}
        placeholder="60%"
        value={data.education.tenth.mathPercentage==="XX %"? "": data.education.tenth.mathPercentage}
        onChange={v => onChange(`data.education.tenth.mathPercentage`, v)}
      />

      <div className="grid grid-cols-2 col-gap-4">
        <TextField
          className="mb-6"
          label={'Starting Year'}
          placeholder="2011"
          value={data.education.tenth.startingYear==="MMM YYYY"? "":data.education.tenth.startingYear}
          onChange={v => onChange(`data.education.tenth.startingYear`, v)}
        />

        <TextField
          className="mb-6"
          label={'Ending Year'}
          placeholder="2012"
          value={data.education.tenth.endYear==="MMM YYYY"?"":data.education.tenth.endYear}
          onChange={v => onChange(`data.education.tenth.endYear`, v)}
        />
      </div>
      
    </div>
  )
}

const TwelvethClass = ({onChange, data, heading}) => {
  const[twelvethShow, setTwelvethShow]=useState(false);
  return(
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading title={heading} setOpen={setTwelvethShow} isOpen={twelvethShow} />
      <div className={`mt-6 ${twelvethShow ? 'block' : 'hidden'}`}>
        <TwelvethClassForm onChange={onChange} data={data} />
      </div>
    </div>
  )
}
const TwelvethClassForm = ({onChange, data}) => {
  return(
    <div>

      <Dropdown
        className='mb-6'
        label={'Heading'}
        value={data.education.twelveth.heading}
        onChange={x => onChange('data.education.twelveth.heading', x)}
        options={["Intermediate", "Polytechnic"]}
        optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
        )}
      />

      {/* {<TextField
        className="mb-6"
        label={'Heading'}
        placeholder="Inter"
        value={data.education.twelveth.heading}
        onChange={v => onChange(`data.education.twelveth.heading`, v)}
      />} */}

      <Dropdown
        className='mb-6'
        label={'School Type'}
        value={data.education.twelveth.schoolType}
        onChange={x => onChange('data.education.twelveth.schoolType', x)}
        options={["Private","Government"]}
        optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
        )}
      />

      <TextField
        className="mb-6"
        label={'School Name'}
        placeholder="School Name"
        value={data.education.twelveth.schoolName==="XXXXXX XXXXX"? "" : data.education.twelveth.schoolName}
        onChange={v => onChange(`data.education.twelveth.schoolName`, v)}
      />

      <Dropdown
        className='mb-6'
        label={'School Board'}
        value={data.education.twelveth.schoolBoard}
        onChange={x => onChange('data.education.twelveth.schoolBoard', x)}
        options={["CBSE","State Board","ICSE"]}
        optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
        )}
      />

      <TextField
        className="mb-6"
        label={'Percentage/Grade'}
        placeholder="60%"
        value={data.education.twelveth.totalPercentage==="XX %"? "": data.education.twelveth.totalPercentage}
        onChange={v => onChange(`data.education.twelveth.totalPercentage`, v)}
      />

      <TextField
        className="mb-6"
        label={('Math Percentage/marks')}
        placeholder="60%"
        value={data.education.twelveth.mathPercentage==="XX %"? "" : data.education.twelveth.mathPercentage}
        onChange={v => onChange(`data.education.twelveth.mathPercentage`, v)}
      />

      <div className="grid grid-cols-2 col-gap-4">

        <TextField
          className="mb-6"
          label={'Starting Year'}
          placeholder="2011"
          value={data.education.twelveth.startingYear==="MMM YYYY"? "": data.education.twelveth.startingYear}
          onChange={v => onChange(`data.education.twelveth.startingYear`, v)}
        />

        <TextField
          className="mb-6"
          label={'Ending Year'}
          placeholder="2012"
          value={data.education.twelveth.endYear==="MMM YYYY"? "": data.education.twelveth.endYear}
          onChange={v => onChange(`data.education.twelveth.endYear`, v)}
        />

      </div>
      
    </div>
  )
}

const Form = ({ item, onChange, identifier = '' }) => {

  return (
    <div>
      <TextField
        className="mb-6"
        label={'Name'}
        placeholder="Harvard University"
        value={item.name}
        onChange={v => onChange(`${identifier}name`, v)}
      />

      <TextField
        className="mb-6"
        label={'Major'}
        placeholder="Masters in Computer Science"
        value={item.major}
        onChange={v => onChange(`${identifier}major`, v)}
      />

      <TextField
        className="mb-6"
        label={'Grade'}
        placeholder="7.2 CGPA"
        value={item.grade}
        onChange={v => onChange(`${identifier}grade`, v)}
      />

      <div className="grid grid-cols-2 col-gap-4">
        <TextField
          className="mb-6"
          label={'Start Date'}
          placeholder="March 2018"
          value={item.start}
          onChange={v => onChange(`${identifier}start`, v)}
        />

        <TextField
          className="mb-6"
          label={'End Date'}
          placeholder="June 2022"
          value={item.end}
          onChange={v => onChange(`${identifier}end`, v)}
        />
      </div>

      <TextArea
        rows="5"
        className="mb-6"
        label={'Description'}
        value={item.description}
        onChange={v => onChange(`${identifier}description`, v)}
      />
    </div>
  );
};

const AddItem = ({ heading, dispatch }) => {
  const [isOpen, setOpen] = useState(false);
  const [item, setItem] = useState({
    id: uuidv4(),
    enable: true,
    name: '',
    major: '',
    start: '',
    end: '',
    grade: '',
    description: '',
  });

  const onChange = (key, value) => setItem(set({ ...item }, key, value));

  const onSubmit = () => {
    if (item.name === '' || item.major === '') return;

    addItem(dispatch, 'education', item);

    setItem({
      id: uuidv4(),
      enable: true,
      name: '',
      role: '',
      start: '',
      end: '',
      grade: '',
      description: '',
    });

    setOpen(false);
  };

  return (
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading heading={"Another Degree"} setOpen={setOpen} isOpen={isOpen} />

      <div className={`mt-6 ${isOpen ? 'block' : 'hidden'}`}>
        <Form item={item} onChange={onChange} />
        <AddItemButton onSubmit={onSubmit} />
      </div>
    </div>
  );
};

const Item = ({ item, index, onChange, dispatch, first, last }) => {
  const [isOpen, setOpen] = useState(false);
  const identifier = `data.education.items[${index}].`;

  return (
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading title={item.name} setOpen={setOpen} isOpen={isOpen} />

      <div className={`mt-6 ${isOpen ? 'block' : 'hidden'}`}>
        <Form item={item} onChange={onChange} identifier={identifier} />

        <ItemActions
          dispatch={dispatch}
          first={first}
          identifier={identifier}
          item={item}
          last={last}
          onChange={onChange}
          type="education"
        />
      </div>
    </div>
  );
};

export default EducationTab;
