/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useContext } from 'react';
import { PanZoom } from 'react-easy-panzoom';

import AppContext from '../context/AppContext';
import PageContext from '../context/PageContext';

import templates from '../templates/index';
import PageController from '../shared/PageController';

const ResumePage = ({ styles, pageStyle }) => {
    const pageRef = useRef(null);
    const panZoomRef = useRef(null);

    const context = useContext(AppContext);
    const { state, dispatch } = context;
    const { theme } = state;

    const pageContext = useContext(PageContext);
    const { setPageRef, setPanZoomRef } = pageContext;

    useEffect(() => {
        setPageRef(pageRef);
        setPanZoomRef(panZoomRef);
        const storedState = JSON.parse(localStorage.getItem('state'));
        dispatch({ type: 'import_data', payload: storedState });
    }, [dispatch, setPageRef, setPanZoomRef]);

    return (
<>
        <div className="flex lg:absolute lg:z-10 justify-center lg:my-8">
            <PanZoom
                ref={panZoomRef}
                // minZoom="0.4"
                // autoCenter
                disabled
                // autoCenterZoomLevel={1.0}
                // enableBoundingBox
                boundaryRatioVertical={0.8}
                boundaryRatioHorizontal={0.8}
                style={{ outline: 'none' }}
            >
                <div id="page" ref={pageRef} className={`shadow-2xl break-words ${pageStyle}`}>
                    {templates.find(x => theme.layout.toLowerCase() === x.key).component()}
                </div>
            </PanZoom>
        </div>
            <PageController styles={styles} /></>
    );
};

export default ResumePage;
