import React, { useEffect, useState, useContext } from 'react';
import { CSSTransition, TransitionGroup, } from 'react-transition-group';

import firebase from '../../firestore/Firestore';
import AppContext from '../../context/AppContext';

const db = firebase.firestore();

const Admin = () => {

    const context = useContext(AppContext);
    const { state } = context;

    const addNotification = (value) => {
        db.collection('Admin').doc('Notification').update({ Notification: firebase.firestore.FieldValue.arrayUnion(value) });
    }

    const deleteNotification = (value) => {
        db.collection('Admin').doc('Notification').update({ Notification: firebase.firestore.FieldValue.arrayRemove(value) })
    }

    return (
        <div className="centered">
            <div className="text-center text-2xl lg:text-4xl font-semibold">BroadCast Notifications</div>
            <div className="border overflow-scroll" style={{maxHeight: '26rem'}}>
            <TransitionGroup className="w-full text-center font-semibold mt-6">
                {state.notification.map((x, index) => (
                    <CSSTransition
                            key={index}
                            timeout={500}
                            classNames="item"
                        >
                    <div className="flex justify-center items-center border">
                        <div key={index} className="p-2 texr-xl lg:text-xl my-4">
                            <div className="">{x}</div>
                        </div>
                        <button className="p-2 bg-red-600 text-white rounded shadow-md mx-4 lg:text-2xl hover:bg-red-700" onClick={()=>deleteNotification(x)}>Delete</button>
                    </div>
                    </CSSTransition>
                ))}
                </TransitionGroup>
            </div>
            <div className="mt-4 text-center">
                <form onSubmit={(x)=>{x.preventDefault(); addNotification(x.target.value.value)}} >
                    <label className="uppercase tracking-wide text-gray-600 text-base font-semibold mb-2 lg:text-2xl">Add Notification</label>
                    <input required type="text" id="value" name="value" className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl my-2" />
                    <input type="submit" value="Submit" className="px-2 py-1 bg-red-600 rounded text-white hover:bg-red-700 lg:text-3xl"/>
                </form>
            </div>

        </div>

    )
}

export default Admin;