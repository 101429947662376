import React from 'react';

const Footer = ({ next, previous, finish, current, total }) => {
    return (
        <React.Fragment>
            <div className={`${current===1? "block": "flex justify-between"}`}>
                {current === 1 ?
                    null :
                    <button className="mx-2 p-2 shadow-md hover:shadow-2xl rounded-lg hover:bg-gray-100 inline-flex justify-center items-center"
                        onClick={previous}
                    >
                        <span className="material-icons text-4xl">keyboard_arrow_left</span>
                        <span className="text-xl font-semibold px-2">Previous</span>
                    </button>
                }

                {current === total ?
                    <button className="mx-2 p-2 shadow-md hover:shadow-2xl rounded-lg hover:bg-gray-100 inline-flex justify-center items-center"
                        onClick={finish}
                    >
                        <span className="text-xl font-semibold px-2">Finish</span>
                        <span className="material-icons text-4xl">done</span>
                    </button>
                    :

                    <button className="mx-2 p-2 shadow-md hover:shadow-2xl rounded-lg hover:bg-gray-100 inline-flex justify-center items-center float-right"
                        onClick={next}
                    >
                        <span className="text-xl font-semibold px-2">Next</span>
                        <span className="material-icons text-4xl">keyboard_arrow_right</span>
                    </button>}
            </div>
        </React.Fragment>


    )
}

export default Footer;