/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useContext, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AppContext from '../../context/AppContext';
import PageContext from '../../context/PageContext';
import firebase from '../../firestore/Firestore';

import LeftSidebar from '../LeftSidebar/LeftSidebar';
import NavBar from '../NavBar';
import Home from '../Home/Home';
import ColorsTab from '../Customization/tabs/Colors';
import FontsTab from '../Customization/tabs/Fonts';
// import ActionsTab from '../Customization/tabs/Actions';
import TemplatesModal from '../Customization/TemplatesModal';
import ResumePage from '../ResumePage';
import ResumeModal from '../Customization/ResumeModal';
import AuthModal from '../auth/AuthModal';
import PrintDialog from '../../shared/PrintDialog';
import Admin from '../Admin/Admin';

const db = firebase.firestore();
let doc = db.collection('Admin').doc('Notification');

const App = () => {

  const context = useContext(AppContext);
  const { state, dispatch } = context;
  const { theme } = state;

  const pageContext = useContext(PageContext);
  const { setPageRef, setPanZoomRef } = pageContext;
  const { setAuthPreview, isPrintDialogOpen } = pageContext;

  const onChange = (key, value) => {
    dispatch({ type: 'on_input', payload: { key, value, }});
    dispatch({ type: 'save_data' });
  };

  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem('state'));
    dispatch({ type: 'import_data', payload: storedState });
  }, [dispatch, setPageRef, setPanZoomRef]);

  //For BroadCasted Notifications
  useEffect(() => {
    // if (firebase.auth().currentUser) {
      doc.onSnapshot(docSnapshot => {
        dispatch({ type: 'on_input', payload: { value: docSnapshot.data().Notification, key: 'notification' } });
        dispatch({ type: 'save_data' });
      }, err => {
        console.log(`Encountered error: ${err}`);
      });
    // }
  }, [firebase.auth().currentUser]);

  return (
    <Suspense fallback="Loading...">
      <div className="flex flex-col h-screen">
        <NavBar />
        <AuthModal />
        <PrintDialog firstName={state.data.profile.firstName} lastName={state.data.profile.lastName} />
        <Router>
          <Switch>
            <Route path="/resume">
              {state.authState ? setAuthPreview(false) : window.location.pathname === "/resume" ? setAuthPreview(true) : null}
              <div className={`grid grid-cols-3 overflow-y-scroll ${state.darkMode? "bg-gray-700": ""}
                  lg:flex lg:flex-col
                  ${state.authState? "" : "blurBackground"}
              `}>
                <TemplatesModal />
                <ResumeModal />

                <div className="z-10 col-span-1 h-auto relative z-10 overflow-scroll relative
                     lg:col-span-3 lg:my-10 lg:mx-10 lg:rounded-lg 
                ">
                  <LeftSidebar />
                </div>

                <div className="relative z-10 h-screen col-span-2 flex justify-center overflow-y-scroll my-8
                    lg:h-auto lg:initial
                ">
                  <ResumePage pageStyle={isPrintDialogOpen ? "": "lg:hidden"} />
                </div>
              </div>}
            </Route>
            <Route path="/colors">
              <ColorsTab theme={theme} onChange={onChange} />
            </Route>
            <Route path="/fonts">
              <FontsTab theme={theme} onChange={onChange} />
            </Route>
            {/* <Route path="/settings">
              <ActionsTab data={data} theme={theme} dispatch={dispatch} />
            </Route> */}
            <Route path="/admin">
              <Admin />
            </Route>
            <Route path="/">
              {/* {state.authState ? setAuthPreview(false) : window.location.pathname === "/" ? setAuthPreview(true) : null} */}
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
};

export default App;
