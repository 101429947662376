import React, { useContext, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import PageContext from '../context/PageContext';
import AppContext from '../context/AppContext';
import User from './User/UserModal';
import Profile from './User/Profiles';
import Notification from './User/Notification';
import logo from '../assets/images/logo.ico';

const NavBar = () => {

    const pageContext = useContext(PageContext);
    const { authPreview, setAuthPreview, profilePreview, setProfilePreview } = pageContext;
    const context = useContext(AppContext);
    const { state, dispatch } = context;
    const [showUser, setShowUser] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    return (
        <nav className={`${state.darkMode ? 'bg-gray-900' : 'bg-teal-700'} p-6 z-30 shadow-xl`}>
            <div className="w-full block flex-grow flex items-center">
                <div className="lg:text-4xl lg:px-4 text-lg flex-grow">
                    {/* <img src={logo} alt="logo" className="h-16 lg:hidden inline"/> */}
                    <a href="/" className={`mt-4 hover:text-white mr-4 ${window.location.pathname === "/" ? "text-white" : "text-teal-200"}`}>
                        Home
                    </a>
                    <a href="/resume" className={`mt-4 hover:text-white mr-4 ${window.location.pathname === "/resume" ? "text-white" : "text-teal-200"}`}>
                        Resume
                    </a>
                    <a href="/colors" className={`mt-4 hover:text-white mr-4 ${window.location.pathname === "/colors" ? "text-white" : "text-teal-200"}`}>
                        Colors
                    </a>
                    <a href="/fonts" className={`mt-4 hover:text-white mr-4 ${window.location.pathname === "/fonts" ? "text-white" : "text-teal-200"}`}>
                        Fonts
                    </a>
                </div>
                {state.authState === true ? <>
                    <CSSTransition in={profilePreview} timeout={200} classNames="fade" mountOnEnter unmountOnExit>
                        <Profile close={() => { setProfilePreview(false); setShowUser(false) }} />
                    </CSSTransition>
                    <div className="relative mr-6">
                        <button onClick={() => { setShowNotification(!showNotification); setShowUser(false) }} className="material-icons text-4xl text-white cursor-pointer rounded-full lg:text-6xl">notifications_none</button>
                        <div onClick={() => { setShowNotification(!showNotification); setShowUser(false) }} className="px-2 text-xs absolute top-0 rounded text-white py-1 lg:text-2xl cursor-pointer" style={{ backgroundColor: "#ff1717", right: '-0.5rem' }}>{state.notification.length}</div>
                        <CSSTransition in={showNotification} timeout={200} classNames="user" mountOnEnter unmountOnExit><Notification /></CSSTransition>
                    </div>
                    <div className="relative mr-4">
                        <button onClick={() => { setShowUser(!showUser); setShowNotification(false) }} className="material-icons text-4xl text-white cursor-pointer border-2 rounded-full hover:border-gray-400 lg:text-6xl">account_circle</button>
                        <CSSTransition in={showUser} timeout={200} classNames="user" mountOnEnter unmountOnExit><User /></CSSTransition>
                    </div>
                </>
                    :
                    <button onClick={() => { setAuthPreview(!authPreview); }} className="text-teal-200 hover:text-black hover:bg-white font-semibold mr-4 lg:text-3xl border px-4 py-2">
                        Login
                    </button>
                }
                {/* <input type="checkbox" onChange={()=> dispatch({type: "on_input", payload: { key: "darkMode", value: !state.darkMode }}) } /> */}
            </div>
        </nav>
    )
}

export default NavBar;

