import React from 'react';

const TextField = ({
  label,
  placeholder,
  value,
  onChange,
  className,
  disabled = false,
  type = 'text',
  tip,
  required=false,
}) => (
    <div className={`w-full flex flex-col ${className}`}>
      {label && (
        <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2 lg:text-2xl">
          {label}
        </label>
      )}
      <div className='tooltip'>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl"
          type={type}
          disabled={disabled}
          value={value}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          onFocus={(event) => { event.target.select() }}
          required={required}
        />
        {tip ? <span className="tooltip-text bg-green-100 border rounded border-green-500 text-green-700 mt-2 lg:text-3xl">Tip: {tip}</span> : null}
      </div>

    </div>
  );

export default TextField;
