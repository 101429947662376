import React, { useState, useContext } from 'react';

import AppContext from '../../context/AppContext';
import Ques1 from './tabs/First';
import Ques2 from './tabs/Second';
import Header from './Header';
import Footer from './Footer';

const Quiz = () => {

    const context = useContext(AppContext);
    const { state, dispatch } = context;
    const { data } = state;

    const [currentTab, setCurrentTab] = useState(1);

    const onChange = (key, value) => {
        dispatch({
            type: 'on_input',
            payload: {
                key,
                value,
            },
        });

        dispatch({ type: 'save_data' });
    };

    const renderTabs = () => {
        switch (currentTab) {
            case 1:
                return <Ques1 data={data} onChange={onChange} />;
            case 2:
                return <Ques2 data={data} onChange={onChange} />;
            case 3:
                return <h1>3rd Field</h1>
            default:
                return null;
        }
    };

    return (
        <div className="uppercase p-10 rounded-lg shadow-2xl w-2/3">
            <Header current={currentTab} total={2} />
            <hr className="my-4" />

            <div>{renderTabs()}</div>

            <hr className="my-4" />
            <Footer current={currentTab} total={2} next={() => setCurrentTab(currentTab + 1)} previous={() => setCurrentTab(currentTab - 1)} finish={()=>{window.location.replace("/resume")}}/>
        </div>
    )
}

export default Quiz;