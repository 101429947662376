import firebase from 'firebase';
// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCsrgi0Ls14rVctnfMNAuV16o6pQJy2RXs",
    authDomain: "resume-builder-01.firebaseapp.com",
    databaseURL: "https://resume-builder-01.firebaseio.com",
    projectId: "resume-builder-01",
    storageBucket: "resume-builder-01.appspot.com",
    messagingSenderId: "697514719464",
    appId: "1:697514719464:web:0ba38bce5f467d548bf15d",
    measurementId: "G-P0LTQQ15T8"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

export default firebase;