import React, { useState, useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import firebase from '../../firestore/Firestore';
import AppContext from '../../context/AppContext';
import PageContext from '../../context/PageContext';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
import logo from '../../assets/images/CVKart-HD.png';
import Loading from '../LoadingAnimation/Loading';

const AuthModal = () => {

    const pageContext = useContext(PageContext);
    const { authPreview, setAuthPreview } = pageContext;
    const context = useContext(AppContext);
    const { state, dispatch } = context;
    const [logIn, setLogIn] = useState(true);
    const [resetPassword, setResetPassword] = useState(false);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async function (user) {
            if (user) {

            } else {
                dispatch({
                    type: 'on_input',
                    payload: {
                        key: 'loading.state',
                        value: false
                    }
                })
                dispatch({
                    type: 'auth_state',
                    payload: { value: false }
                })
                dispatch({
                    type: 'on_input',
                    payload: {
                        key: 'allProfiles',
                        value: null
                    }
                })
                dispatch({ type: 'on_input', payload: { value: ['loading...', 'loading...'], key: 'notification' } });
                dispatch({ tpye: 'reset' });
                dispatch({ type: 'save_data' });
            }
        });
    }, [])


    return (
        <React.Fragment>
            <CSSTransition in={authPreview} timeout={200} classNames="fade" mountOnEnter unmountOnExit>
                <div
                    onClick={
                        () => { setAuthPreview(false) }}
                    className={`fixed h-full w-full z-20`}
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}>
                    <div className="centered p-10 rounded-lg shadow-2xl lg:w-2/3 w-2/3 bg-white overflow-scroll mt-10"
                        style={{ maxHeight: '80%' }}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    >
                        <div className="w-full flex justify-center items-center">
                            <img src={logo} alt="logo" className={`h-20 lg:h-24`} />
                        </div>
                        {state.loading.state ?
                            <div className="w-full flex justify-center items-center py-10" style={{height: "18rem"}}><Loading /></div>
                            :
                            <>{window.location.pathname === "/resume" && state.authState === false && state.loading.state === false ? <div className="text-gray-900 mt-2 text-center font-semibold text-medium text-red-700 lg:text-2xl">Please Login to Continue</div> : null}
                                {resetPassword ? <ResetPassword goBack={() => { setResetPassword(false); dispatch({ type: 'on_input', payload: { key: "errorMessage", value: null } }) }} /> :
                                    <>
                                        {logIn ?
                                            <SignIn createAccount={() => { setLogIn(false); dispatch({ type: 'on_input', payload: { key: "errorMessage", value: null } }) }} forgotPassword={() => { setResetPassword(true); dispatch({ type: 'on_input', payload: { key: "errorMessage", value: null } }) }} />
                                            :
                                            <SignUp signIn={() => { setLogIn(true); dispatch({ type: 'on_input', payload: { key: "errorMessage", value: null } }) }} />
                                        }
                                    </>
                                }</>
                        }
                    </div>
                </div>
            </CSSTransition>
        </React.Fragment>
    )
}

export default AuthModal;