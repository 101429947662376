import React, { useEffect, useState, useContext } from 'react';
import firebase from '../../firestore/Firestore';
import pullAt from 'lodash/pullAt';
import { CSSTransition, TransitionGroup, } from 'react-transition-group';
import { toast } from 'react-toastify';

import TextField from '../../shared/TextField';
import AppContext from '../../context/AppContext';

const Profiles = ({ close }) => {

    var profile_Name = '';

    const db = firebase.firestore();
    const context = useContext(AppContext);
    const { state, dispatch } = context;

    const [profiles, setProfiles] = useState([{ profileName: 'loading...' }]);
    const [errorMessage, setErrorMessage]= useState(null);

    var user = firebase.auth().currentUser;

    useEffect(() => {
        if (user && state.loading.state===false) {
            setProfiles(state.allProfiles);
        } else {

        }
    }, [user, db,  state.allProfiles])

    const selectedProfile = (index) => {
        dispatch({
            type: 'load_data',
            payload: {
                value: profiles[index]
            }
        });
        dispatch({
            type: 'on_input',
            payload: {
                key: 'currentProfile',
                value: index
            }
        })
        dispatch({ type: 'save_data' });
    }

    const deleteProfile = (index) => {
        if(profiles.length===1){
            return toast(`One Profile is mandatory`, {
                bodyClassName: 'text-center text-white py-2 bg-red-600 lg:text-2xl',
            })
        }
        var arrCopy = [...profiles];
        pullAt(arrCopy, index);
        db.collection("resumeData").doc(user.uid).set({ profiles: arrCopy }).then(() => {
            selectedProfile(0);
            dispatch({
                type: 'on_input',
                payload: {
                    key: 'allProfiles',
                    value: arrCopy
                }})   
            dispatch({ type: 'save_data' });
            // setProfiles(arrCopy);
            toast(`Profile has been deleted`, {
                bodyClassName: 'text-center text-gray-800 py-2 lg:text-2xl',
            })
        }).catch(() => {
            toast(`There seems to be some problem, please try again`, {
                bodyClassName: 'text-center text-gray-800 py-2 lg:text-2xl',
            });
        })
    }

    const createProfile = () => {
        if(profile_Name=== ''){
            return setErrorMessage('Please Enter a Profile Name')
        }else{
            var count=0;
            profiles.map(x=>{if(x.profileName===profile_Name){count++}})
            if(count===0){
                setErrorMessage(null);
            }else{
                return setErrorMessage('Profile Name Already Exist')
            }
        }
        var newProfile = {...context.initialState.data};
        newProfile.profileName = profile_Name;
        var arr = [...profiles, newProfile];
        db.collection("resumeData").doc(user.uid).set({ profiles: arr }).then((x) => {
            db.collection("resumeData").doc(user.uid).get().then((x) => { 
                var fetchedData = x.data() 
                dispatch({
                    type: 'on_input',
                    payload: {
                        key: 'allProfiles',
                        value: fetchedData.profiles
                    }})
                dispatch({ type: 'save_data' });
            });
        }).catch((x) => { console.log(x) })
    }

    return (
        <div className="fixed z-20">
            <div className="bg-black opacity-75 fixed w-screen h-screen z-10 top-0 left-0" onClick={close}></div>
            <div className="centered p-10 rounded-lg shadow-2xl lg:w-2/3 w-2/3 bg-white overflow-scroll z-10">
                <div className="text-center font-semibold text-xl lg:text-3xl">Profile Manager</div>
                <TransitionGroup className="grid grid-cols-2 gap-3 w-full text-center font-semibold mt-6">
                    {profiles.map((x, index) => (
                        <CSSTransition
                            key={x.profileName}
                            timeout={500}
                            classNames="item"
                        >
                            <>
                                <div className={`shadow-xl p-6 text-center break-all rounded text-md col-span-1 lg:col-span-2 cursor-pointer lg ${state.currentProfile === index ? "border-2 border-red-600 border-opacity-25 shadow-2xl" : null}`} onClick={() => { selectedProfile(index) }}>
                                    <div key={index} className="lg:text-xl">{x.profileName}</div>
                                    <button className="px-3 py-1 rounded shadow-xl mt-4 bg-red-600 text-white hover:bg-red-700 lg:text-2xl" onClick={(e) => { e.stopPropagation(); deleteProfile(index) }}>Delete</button>
                                </div>
                            </>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
                <div className="w-full flex justify-center items-center">
                    <div className="shadow-xl p-6 text-center break-all rounded text-md w-1/2 lg:w-full cursor-pointer bg-teal-200 border mt-6 hover:bg-teal-300">
                        <div className="lg:text-2xl">Create New Profile</div>
                        <TextField
                            className="text-white mt-2"
                            label={`Profile Name`}
                            onChange={v => { profile_Name = v }}
                        />
                        <button className="mt-4 text-2xl bg-teal-400 rounded w-full hover:bg-teal-600" onClick={createProfile}>+</button>
                        <div className="text-red-600">{errorMessage}</div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Profiles;