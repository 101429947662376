import React from 'react';

const ItemHeading = ({ title, heading, isOpen, setOpen }) => {

  return (
    <div
      className="flex justify-between items-center cursor-pointer"
      onClick={() => setOpen(!isOpen)}
    >
      <h6 className="text-sm font-medium lg:text-2xl">
        {typeof heading === 'undefined' ? title : (`Add ${heading}`)}
      </h6>
      <i className="material-icons lg:text-2xl">{isOpen ? 'expand_less' : 'expand_more'}</i>
    </div>
  );
};

export default ItemHeading;
