import React, { createContext, useReducer } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import remove from 'lodash/remove';

import demoData from '../assets/demo/data.json';
import { move } from '../utils';

const initialState = {
  darkMode: false,
  currentProfile: 0,
  allProfiles: [],
  authState: false,
  loading:{
    state:false,
    message: "WAIT, WE ARE SIGNING YOU IN ......",
  },
  errorMessage: null,
  notification: ["Loading...", "Loading..."],
  data: {
    profileName: 'Default Profile',
    profile: {
      heading: 'Profile',
      firstName: 'Your',
      lastName: "Full Name",
      subtitle: "XXXX Trained B.Tech Fresher - Branch - YOP",
      address: {
        line1: 'XXXXXXXXXXXXX',
        line2: '',
        line3: '',
      },
      phone: '+91 00000 00000',
      website: 'https://www.linkedin.com/in/yourprofile',
      email: 'xxxxxxx@xxxx.com',
      currentLocation: 'Hyderabad',
    },
    careerObjective: '',
    job: '',
    objective: {
      enable: true,
      heading: 'Objective',
      body: 'XXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX xxxxxxxxxx XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXXXXXXX XXXXX XXXXX XXXXX XXXXXXXXX',
    },
    work: {
      enable: false,
      heading: 'Work Experience',
      items: [],
    },
    education: {
      enable: true,
      heading: 'Education',
      graduate: '',
      postGraduate: {
        degree: '',
        specilization: '',
        collegeName: '',
        percentage: '',
        startingYear: '',
        endYear: '',
      },
      graduation: {
        degree: "B.Tech",
        branch: "Computer Scienece",
        university: "XXXXXX University",
        collegeName: "XXXXXX College",
        percentage: "xx %",
        startingYear: "MMM YYYY",
        endYear: "MMM YYYY"
      },
      tenth: {
        heading: "High School",
        schoolName: "XXXXXX School, Town Name",
        schoolBoard: "CBSE",
        schoolType: "Private",
        startingYear: "MMM YYYY",
        endYear: "MMM YYYY",
        mathPercentage: "XX %",
        totalPercentage: "XX%"
      },
      twelveth: {
        heading: "Intermediate",
        schoolName: "XXXXXX XXXXX",
        schoolBoard: "State Board",
        schooltype: "",
        startingYear: "MMM YYYY",
        endYear: "MMM YYYY",
        mathPercentage: "XX %",
        totalPercentage: "XX %",
        schoolType: "Private"
      },
      items: [],
    },
    awards: {
      enable: true,
      heading: 'Achievements',
      items: [
        {
          id: "121f0976-18cb-4e46-921d-0e156b6bf7fb",
          title: "Achievement",
          subtitle: "***, ****",
          description: "",
          enable: true
        },
        {
          id: "e5f27346-72ad-4d4f-bab3-726a111e4932",
          title: "Achievement",
          subtitle: "***, ****",
          description: "",
          enable: true
        },
        {
          id: "f71ba9bc-8c14-46b5-99dd-e1333e9aceb9",
          title: "Achievement",
          subtitle: "***, ****",
          description: "",
          enable: true
        }
      ],
    },
    certifications: {
      enable: true,
      heading: 'Certifications',
      items: [],
      intership: {
        items: [],
      },
      projects: {
        items: [],
      }
    },
    projects: {
      enable: true,
      heading: 'Projects',
      items: [
        {
          id: "2b2ac0bc-6b75-46f4-ba3f-70f6114a73e4",
          enable: true,
          title: "Random Project Title",
          problemStatement: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          toolsTech: "Tools1, Tools2",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          learnings: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          start: "Mar ****",
          end: "June ****"
        },
        {
          id: "4fa6e583-e02b-462e-b0e2-ef46510c8e2a",
          enable: true,
          title: "Random Project Title",
          problemStatement: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          toolsTech: "Tools1, Tools2",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          learnings: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          start: "Mar ****",
          end: "June ****"
        }
      ],
    },
    internships: {
      enable: true,
      heading: 'Internship',
      items: [
        {
          id: "78717305-cd25-402a-9cba-b22e9bdc7483",
          enable: true,
          title: "Internship Title 1",
          companyName: "Random.Inc",
          toolsTech: "Tools, Tools2",
          learnings: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          start: "--- ****",
          end: "--- ****",
          role: "XXXX",
          responsibilities: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        }
      ],
    },
    skills: {
      enable: true,
      heading: 'Strengths/Traits',
      items: [
        "Personal Trait",
        "Personal Trait",
        "Personal Trait",
        "Personal Trait"
      ],
    },
    hobbies: {
      enable: true,
      heading: 'Hobbies',
      items: [
        "Hobby",
        "Hobby",
        "Hobby"
      ],
    },
    languages: {
      enable: true,
      heading: 'Technical Skills',
      items: [
        {
          id: "9d34cfcb-c9f0-4d25-ab27-cf81652dd1d0",
          key: "XX",
          value: 5,
          enable: true
        },
        {
          id: "3511a86b-7ea9-44ac-8144-6acc7f3bd54f",
          key: "XX",
          value: 4,
          enable: true
        },
      ],
    },
    database: {
      items: [
        {
          id: "22baf0c5-6259-4351-8773-d04d85c4e9dc",
          enable: true,
          key: "XX",
          value: 5
        },
        {
          id: "070ffcea-1526-4590-8b27-090202a5e189",
          enable: true,
          key: "XX",
          value: 3
        }
      ],
    },
    operatingSystem: {
      items: [
        {
          id: "22baf0c5-6259-4351-8773-d04d85c4e9dc",
          enable: true,
          key: "XX",
          value: 5
        },
        {
          id: "070ffcea-1526-4590-8b27-090202a5e189",
          enable: true,
          key: "XX",
          value: 3
        }
      ],
    },
    toolTechnologies: {
      items: [
        {
          id: "4397e319-b54f-4243-85a6-0d84fef42d08",
          enable: true,
          key: "XX",
          value: 5
        },
        {
          id: "13fcbc91-6f3d-4b16-b291-1cb88610f483",
          enable: true,
          key: "XX",
          value: 3
        }
      ],
    },
    references: {
      enable: true,
      heading: 'References',
      items: [],
    },
    extras: {
      enable: true,
      heading: 'Personal Information',
      items: [
        {
          id: "b0c4fd85-cfda-421e-bd31-008b9aad1dfe",
          key: "Hometown",
          value: "XXXXX, State",
          enable: true
        },
        {
          id: "7f0a4971-9770-4ca7-b135-2b0ccd867879",
          key: "Languages",
          value: "XXXX, XXXXX & XXXX",
          enable: true
        },
        {
          id: "7f2cc3cf-2cc8-4c57-a133-33e84824817f",
          enable: true,
          key: "Date of Birth",
          value: "DD - MMM - YYYY"
        }
      ],
    },
  },
  theme: {
    layout: 'onyx',
    font: {
      family: '',
    },
    colors: {
      background: '#ffffff',
      primary: '#212121',
      accent: '#f44336',
    },
  },
  settings: {
    language: 'en',
  },
};

const reducer = (state, { type, payload }) => {
  let items;

  switch (type) {
    case 'migrate_section':
      return set({ ...state }, `data.${payload.key}`, payload.value);
    case 'add_item':
      items = get({ ...state }, `data.${payload.key}.items`, []);
      items.push(payload.value);
      return set({ ...state }, `data.${payload.key}.items`, items);
    case 'delete_item':
      items = get({ ...state }, `data.${payload.key}.items`, []);
      remove(items, (x) => x === payload.value);
      return set({ ...state }, `data.${payload.key}.items`, items);
    case 'move_item_up':
      items = get({ ...state }, `data.${payload.key}.items`, []);
      move(items, payload.value, -1);
      return set({ ...state }, `data.${payload.key}.items`, items);
    case 'move_item_down':
      items = get({ ...state }, `data.${payload.key}.items`, []);
      move(items, payload.value, 1);
      return set({ ...state }, `data.${payload.key}.items`, items);
    case 'on_input':
      return set({ ...state }, payload.key, payload.value);
    case 'save_data':
      localStorage.setItem('state', JSON.stringify(state));
      return state;
    case 'import_data':
      if (payload === null) return initialState;

      for (const section of Object.keys(initialState.data)) {
        if (!(section in payload.data)) {
          payload.data[section] = initialState.data[section];
        }
      }

      return {
        ...state,
        ...payload,
      };
    case 'load_data':
      return set({ ...state }, 'data', payload.value);
    case 'auth_state':
      return set({...state}, 'authState', payload.value);
    case 'new_profile':
      set({...initialState}, 'data.profileName', payload.value);
      return state;
    case 'load_demo_data':
      return {
        ...state,
        ...demoData,
      };
    case 'reset':
      return set({ ...state }, `data`, initialState.data);
    case 'reset_complete':
      return set({...state}, initialState);
    default:
      return state;
  }
};

const AppContext = createContext(initialState);
const { Provider } = AppContext;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch, initialState }}>{children}</Provider>;
};

export const AppProvider = StateProvider;
export const AppConsumer = AppContext.Consumer;

export default AppContext;
