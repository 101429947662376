import React, { useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { existingUser } from '../../utils/index';
import firebase from '../../firestore/Firestore';
import AppContext from '../../context/AppContext';
import Google from './GoogleSignIn';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    password: Yup.string()
        .min(6, 'Password has to be atleast 6 characters long')
        .required('Required')
});

const SingIn = ({ createAccount, forgotPassword }) => {

    const context = useContext(AppContext);
    const { state, dispatch } = context;
    const db = firebase.firestore();

    const logIn = (e) => {
        dispatch({
            type: 'on_input',
            payload: {
                key: 'loading.state',
                value: true
            }
        })
        dispatch({type: 'reset_complete'});
        firebase.auth().signInWithEmailAndPassword(e.email, e.password).then(async (user) => {
            existingUser(dispatch, db, context, user.user.uid);
        }).catch(function (error) {
            dispatch({
                type: 'on_input',
                payload: {
                    key: 'loading.state',
                    value: false
                }
            })
            // Handle Errors here.
            var errorCode = error.code;
            var errormessage = error.message;
            var errorMessage;
            if (errorCode === "auth/wrong-password") {
                errorMessage = 'You have entered an invalid username or password';
            } else if (errorCode === "auth/user-not-found") {
                errorMessage = 'You have entered an invalid username';
            } else if (errorCode === "auth/too-many-requests") {
                errorMessage = 'Too many unsuccessful login attempts. Please try again later';
            } else {
                errorMessage = errormessage;
            }
            dispatch({
                type: 'on_input',
                payload: {
                    key: "errorMessage",
                    value: errorMessage
                }
            })
            // ...
        });
    }

    return (
        <div className="">

            <div className="text-xl m-2 font-medium text-center lg:text-3xl">Sign in</div>

            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={LoginSchema}
                // validator={() => ({})}
                onSubmit={values => {
                    dispatch({ type: 'on_input', payload: { key: "errorMessage", value: null } });
                    logIn(values);
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className={`w-full flex flex-col my-2`}>
                            <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2 lg:text-2xl">Email</label>
                            <Field required name="email" type="email" placeholder="Enter Email"
                                className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl" />
                            {errors.email && touched.email ? <div className="text-red-600">{errors.email}</div> : null}
                        </div>

                        <div className={`w-full flex flex-col my-2`}>
                            <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2 lg:text-2xl">Password</label>
                            <Field required name="password" type="password" placeholder="Enter password"
                                className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl" />
                            {errors.password && touched.password ? <div className="text-red-600">{errors.password}</div> : null}
                        </div>

                        {state.loading.state ?
                            <div className="font-semibold text-center lg:text-xl">{state.loading.message}</div>
                            : <div className="text-red-700 font-semibold text-center lg:text-xl">{state.errorMessage}</div>}

                        <div className="w-full flex justify-between items-center mb-4 mt-6">
                            <button disabled={state.loading.state} type="submit" value="submit" className="w-full px-5 py-2 bg-blue-600 shadow-md hover:shadow-2xl rounded-lg hover:bg-blue-800 inline-flex justify-center items-center">
                                <span className="text-white font-semibold lg:text-2xl">Login</span>
                            </button>
                        </div>
                    </Form>
                )}

            </Formik>

            <Google message={'SignIn through Google'} />

            <button className="block align-left text-blue-600 font-semibold mt-6 lg:text-2xl" onClick={createAccount}>Create a new Acount?</button>

            <button className="block text-blue-600 font-semibold mt-6 lg:text-2xl" onClick={forgotPassword}>Forgot password?</button>

        </div>
    )
}

export default SingIn;