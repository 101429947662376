import React from 'react';

const Header = ({ current, total }) => {
    return (
        <React.Fragment>
            <div>
                <div className="font-bold inline text-2xl">questionnaire</div>
                <div className="inline ml-5 font-thin"><span className="font-bold text-2xl mr-1">{current}</span>/{total}</div>
            </div>
        </React.Fragment>
    )
}

export default Header;