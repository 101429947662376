import React, { useContext, useEffect } from 'react';

import { newUser, existingUser } from '../../utils/index';
import firebase from '../../firestore/Firestore';
import PageContext from '../../context/PageContext';
import AppContext from '../../context/AppContext';
import googleLogo from '../../assets/images/google-logo.png'

var provider = new firebase.auth.GoogleAuthProvider();

const GoogleSignIn = ({ message }) => {

    const context = useContext(AppContext);
    const { state, dispatch } = context;
    const pageContext = useContext(PageContext);
    const { setAuthPreview } = pageContext;
    const db = firebase.firestore();

    const logIn = (e) => {
        dispatch({ type: 'on_input', payload: { key: "errorMessage", value: null } });
        dispatch({type: 'reset_complete'});
        dispatch({
            type: 'on_input',
            payload: {
                key: 'loading.state',
                value: true
            }
        })
        firebase.auth().signInWithPopup(provider).then(async (result) => {
            if (result.additionalUserInfo.isNewUser) {
                newUser(dispatch, context);
            } else {
                existingUser(dispatch, db, context, result.user.uid);
            }
            console.log('Google SignIn IN')
        }).catch(function (error) {
            dispatch({
                type: 'on_input',
                payload: {
                    key: 'loading.state',
                    value: false
                }
            })

            // var errorCode = error.code;
            var errormessage = error.message;
            // var email = error.email;
            // var credential = error.credential;
            console.log(error);

            // if(error.code="This browser is not supported or 3rd party cookies and data may be disabled."){
            //     errormessage='Please Allow 3rd party Cookies to use Google Login';
            // }

            dispatch({
                type: 'on_input',
                payload: {
                    key: "errorMessage",
                    value: errormessage
                }
            })
        });
    }

    return (
        <button disabled={state.loading.state} onClick={logIn} className="w-full bg-gray-200 rounded-lg shadow-2xl flex justify-center items-center py-2 mt-4 hover:bg-green-100">
            <img src={googleLogo} alt="googleLogo" className="h-8 mr-4 lg:h-10" />
            <div className="font-semibold ml-4 lg:text-2xl">{message}</div>
        </button>
    )
}

export default GoogleSignIn;