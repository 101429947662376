import React, { useContext } from 'react';
import { toast } from 'react-toastify';

import PageContext from '../context/PageContext';
import AppContext from '../context/AppContext';
import firebase from '../firestore/Firestore';

const PageController = ({ styles }) => {
  const pageContext = useContext(PageContext);
  const context = useContext(AppContext);
  const { panZoomRef, setPrintDialogOpen } = pageContext;
  const { setShowTemplates } = pageContext;
  const { resumePreview, setResumePreview } = pageContext;

  const zoomIn = () => panZoomRef.current.zoomIn(2);
  const zoomOut = () => panZoomRef.current.zoomOut(2);
  const centerReset = () => {
    panZoomRef.current.autoCenter(1);
    panZoomRef.current.reset(1);
  };

  const { state, dispatch } = context;
  const db = firebase.firestore();
  var profilesArr = state.allProfiles;
  var user = firebase.auth().currentUser;

  const resetEverything = (key, value) => {
    dispatch({ type: 'reset' });

    dispatch({ type: 'save_data' });
  };

  const loadDemoData = () => {
    dispatch({ type: 'load_demo_data' });
    dispatch({ type: 'save_data' });
  };

  const saveChanges = () => {
    var user = firebase.auth().currentUser;
    var profilesArr = state.allProfiles;
    profilesArr[state.currentProfile]=state.data;
    if (user) {
      db.collection("resumeData").doc(user.uid).set({ profiles: profilesArr }).then(() => {
        toast(`Changes been saved to database `, {
          bodyClassName: 'text-center text-gray-800 py-2 lg:text-2xl',
        })
      }).catch(() => {
        toast(`There seems to be some problem, please try again`, {
          bodyClassName: 'text-center text-gray-800 py-2 lg:text-2xl',
        });
      })
    }
  }

  const ZoomTools = () => {
    return (
      <div className="text-2xl flex justify-center items-center leading-none">
        {/* <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={zoomIn}>
          <i className="material-icons">zoom_in</i>
        </div>

        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={zoomOut}>
          <i className="material-icons">zoom_out</i>
        </div> */}

        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={centerReset}>
          <i className="material-icons">center_focus_strong</i>
        </div>

        <div className="text-gray-400 p-3">|</div>
      </div>
    )
  }

  return (
    <div
      id="pageController"
      className={`fixed z-20 opacity-75 hover:opacity-100 transition-all duration-150 fixed-center ${styles}`}
    // style={{left: "50%", transform: "translateX(-50%)"}}
    >
      <div className="text-2xl px-8 border border-gray-200 rounded-full bg-white flex justify-center items-center leading-none select-none lg:text-4xl">
        {resumePreview ? <ZoomTools /> : null}

        <div className="lg:hidden"><ZoomTools /></div>

        <p
          className="p-3 hover:bg-gray-200 cursor-pointer flex text-lg hidden lg:block"
          onClick={() => { { resumePreview ? setResumePreview(false) : setResumePreview(true) } }}
        >
          Preview
        </p>

        <div className="text-gray-400 p-3 hidden lg:block">|</div>

        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={async () => {
          await (window.print())
          db.collection("resumeData").doc(user.uid).update({ profiles: profilesArr })
        }}>
          <i className="material-icons">print</i>
        </div>

        <div
          className="p-3 hover:bg-gray-200 cursor-pointer flex"
          onClick={() => { setPrintDialogOpen(true) }}
        >
          <i className="material-icons">save</i>
        </div>

        <div className="text-gray-400 p-3">|</div>

        <p
          className="p-3 hover:bg-gray-200 cursor-pointer flex text-lg"
          onClick={() => setShowTemplates(true)}
        >
          Templates
        </p>

        {/* <div className="text-gray-400 p-3">|</div> */}
        {/* 
        <p
          className="p-3 hover:bg-gray-200 cursor-pointer flex text-lg"
          onClick={()=> loadDemoData()}
        >
          Demo Data
        </p> */}

        <div className="text-gray-400 p-3">|</div>

        <p
          className="p-3 hover:bg-gray-200 cursor-pointer flex text-lg"
          onClick={resetEverything}
        >
          Reset
        </p>

        {state.authState ?
          <>
            <div className="text-gray-400 p-3">|</div>

            <p
              className="p-3 hover:bg-gray-200 cursor-pointer flex text-lg"
              onClick={saveChanges}
            >
              Save Changes
        </p> </>
          : null
        }

      </div>
    </div>
  );
};

export default PageController;
