/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext } from 'react';

import PageContext from '../../context/PageContext';

import ResumePage from '../ResumePage';

const ResumeModal = () => {

    const pageContext = useContext(PageContext);
    const { resumePreview, setResumePreview } = pageContext;

    return (
        <>
            {resumePreview ? <div onClick={()=> setResumePreview(false)}>
                <div
                    id="rightSidebar"
                    className="py-8 flex justify-center bg-white shadow-xl rounded w-full h-screen"
                >
                    <ResumePage styles={"hidden"} />
                </div>
            </div> : null}
        </>
    );
};

export default ResumeModal;
