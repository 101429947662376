import React, { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';

import TextField from '../../../shared/TextField';
import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import TextArea from '../../../shared/TextArea';
import { addItem } from '../../../utils';
import ItemActions from '../../../shared/ItemActions';
import ItemHeading from '../../../shared/ItemHeading';
import AddItemButton from '../../../shared/AddItemButton';

const dummy = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."

const Projects = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;

  return (
    <>
      <div className="my-6 grid grid-cols-6 items-center">
        <div className="col-span-1">
          <Checkbox
            checked={data.projects.enable}
            onChange={v => onChange('data.projects.enable', v)}
          />
        </div>
        <div className="col-span-5">
          <TextField
            placeholder="Heading"
            value={data.projects.heading}
            onChange={v => onChange('data.projects.heading', v)}
          />
        </div>
      </div>

      <hr className="my-6" />

      {data.projects.items.map((x, index) => (
        <Item
          item={x}
          key={x.id}
          index={index}
          onChange={onChange}
          dispatch={dispatch}
          first={index === 0}
          last={index === data.projects.items.length - 1}
        />
      ))}

      <AddItem heading={data.projects.heading} dispatch={dispatch} />
    </>
  );
};

const Form = ({ item, onChange, identifier = '' }) => {

  return (
    <div>
      <TextField
        className="mb-6"
        label={'Title'}
        value={item.title === "Random Project Title" ? "" : item.title}
        onChange={v => onChange(`${identifier}title`, v)}
      />

      <TextArea
        className="mb-6"
        label={'Project Description'}
        value={item.description === dummy ? "" : item.description}
        onChange={v => onChange(`${identifier}description`, v)}
      />

      <TextArea
        className="mb-6"
        label={'Problem Statement'}
        value={item.problemStatement === dummy ? "" : item.problemStatement}
        onChange={v => onChange(`${identifier}problemStatement`, v)}
      />

      <TextField
        className="mb-6"
        label={'Tools & Technology Used'}
        value={item.toolsTech === "Tools1, Tools2" ? "" : item.toolsTech}
        onChange={v => onChange(`${identifier}toolsTech`, v)}
      />

      <TextArea
        className="mb-6"
        label={'Learnings'}
        value={item.learnings === dummy ? "" : item.learnings}
        onChange={v => onChange(`${identifier}learnings`, v)}
      />

      <div className="grid grid-cols-2 col-gap-4">
        <TextField
          className="mb-6"
          label={'Start'}
          value={item.start === "Mar ****" ? "" : item.start}
          onChange={v => onChange(`${identifier}start`, v)}
        />

        <TextField
          className="mb-6"
          label={'End'}
          value={item.end === "June ****" ? "" : item.end}
          onChange={v => onChange(`${identifier}end`, v)}
        />
      </div>
    </div>
  );
};

const AddItem = ({ heading, dispatch }) => {
  const [isOpen, setOpen] = useState(false);
  const [item, setItem] = useState({
    id: uuidv4(),
    enable: true,
    title: '',
    problemStatement: '',
    toolsTech: '',
    description: '',
    learnings: '',
    start: '',
    end: '',
  });

  const onChange = (key, value) => setItem(set({ ...item }, key, value));

  const onSubmit = () => {
    if (item.title === '') return;

    addItem(dispatch, 'projects', item);

    setItem({
      id: uuidv4(),
      enable: true,
      title: '',
      problemStatement: '',
      toolsTech: '',
      description: '',
      learnings: '',
      start: '',
      end: '',
    });

    setOpen(false);
  };

  return (
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading heading={heading} setOpen={setOpen} isOpen={isOpen} />

      <div className={`mt-6 ${isOpen ? 'block' : 'hidden'}`}>
        <Form item={item} onChange={onChange} />

        <AddItemButton onSubmit={onSubmit} />
      </div>
    </div>
  );
};

const Item = ({ item, index, onChange, dispatch, first, last }) => {
  const [isOpen, setOpen] = useState(false);
  const identifier = `data.projects.items[${index}].`;

  return (
    <div className="my-4 border border-gray-200 rounded p-5">
      <ItemHeading title={item.title} setOpen={setOpen} isOpen={isOpen} />

      <div className={`mt-6 ${isOpen ? 'block' : 'hidden'}`}>
        <Form item={item} onChange={onChange} identifier={identifier} />

        <ItemActions
          dispatch={dispatch}
          first={first}
          identifier={identifier}
          item={item}
          last={last}
          onChange={onChange}
          type="projects"
        />
      </div>
    </div>
  );
};

export default Projects;