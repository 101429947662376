import React, { useContext } from 'react';

import PageContext from '../../context/PageContext';
import AppContext from '../../context/AppContext';

import templates from '../../templates/index';

const TemplatesTab = ({ theme, onChange }) => {
  return (
    <div className="flex flex-col">
      {templates.map(x => (
        <div key={x.key} className="text-center" onClick={() => onChange('theme.layout', x.key)}>
          <img
            className={`rounded cursor-pointer object-cover border shadow hover:shadow-md ${
              theme.layout.toLowerCase() === x.key
                ? 'border-gray-600 hover:border-gray-600'
                : 'border-transparent '
            } hover:border-gray-500 cursor-pointer`}
            src={x.preview}
            alt={x.name}
          />
          <p className="mt-1 text-sm font-medium">{x.name}</p>
        </div>
      ))}
    </div>
  );
};

const TemplatesModal = () => {
  const context = useContext(AppContext);
  const { state, dispatch } = context;
  const {theme} = state;

  const pageContext = useContext(PageContext);
  const { showTemplates, setShowTemplates } = pageContext;

  const onChange = (key, value) => {
    dispatch({
      type: 'on_input',
      payload: {
        key,
        value,
      },
    });

    dispatch({ type: 'save_data' });
  };

  return (
    <>
    {showTemplates?
    <div className={`absolute inset-0 transition-all duration-200 ease-in-out ${showTemplates ? 'opacity-100 z-20' : 'opacity-0 z-0'}`}
    style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)', height:'110vh' }}
    onClick={() => {
      setShowTemplates(false);
    }}
    >
        <div
            id="rightSidebar"
            className="animated slideInRight py-8 px-12 bg-white shadow-xl rounded w-1/3 lg:w-1/2 h-screen flex flex-col"
            style={{overflow:'scroll', float:"right", height:'110vh'}}
        >
            <TemplatesTab theme={theme} onChange={onChange} />
            <button
                    type="button"
                    onClick={() => {
                      setShowTemplates(false);
                    }}
                    className="mt-6 border border-red-600 text-red-600 hover:bg-red-600 hover:text-white text-sm font-medium py-2 px-5 rounded"
                >
                    <div className="flex justify-center items-center">
                    <i className="material-icons mr-2 font-bold text-base">close</i>
                    <span className="text-sm">{'Cancel'}</span>
                    </div>
            </button>
        </div>
        </div>:null}
        </>
  );
};

export default TemplatesModal;