import Onyx, { Image as OnyxPreview } from './onyx';

export default [
  {
    key: 'onyx',
    name: 'Onyx',
    component: Onyx,
    preview: OnyxPreview,
  },
];
