import React from 'react';

import TextField from '../../../shared/TextField';
// import Dropdown from '../../../shared/Dropdown';

const ProfileTab = ({ data, onChange }) => {

  return (
    <div id="mobileFont">
      {/* <TextField
        className="mb-6"
        label={'Photo URL'}
        placeholder="https://i.imgur.com/..."
        value={data.profile.photo==="xxxx"? "": data.profile.photo}
        onChange={v => onChange('data.profile.photo', v)}
      /> */}

      <div className="grid grid-cols-2 col-gap-4">
        <TextField
          className="mb-6"
          label={'First Name'}
          placeholder="Jane"
          value={data.profile.firstName==="Your"? "": data.profile.firstName}
          onChange={v => onChange('data.profile.firstName', v)}
          tip={"Enter Your Full Name"}
        />

        <TextField
          className="mb-6"
          label={'Last Name'}
          placeholder="Doe"
          value={data.profile.lastName==="Full Name"? "": data.profile.lastName}
          onChange={v => onChange('data.profile.lastName', v)}
        />
      </div>
      {/* 
      <Dropdown
        className='mb-6'
        label={('Gender')}
        value={data.profile.gender}
        onChange={x => onChange('data.profile.gender', x)}
        options={["Female","Male"]}
        optionItem={x => (
          <option key={x} value={x}>
            {x}
          </option>
        )}
      /> */}

      <TextField
        className="mb-6"
        label={'Subtitle'}
        placeholder="Full-Stack Web Developer"
        value={data.profile.subtitle==="XXXX Trained B.Tech Fresher - Branch - YOP"? "":data.profile.subtitle}
        onChange={v => onChange('data.profile.subtitle', v)}
        tip={"Please Enter Subtitles"}
      />

      <hr className="my-6" />

      <TextField
        className="mb-6"
        label={'Phone Number'}
        placeholder="+91 00000 00000"
        value={data.profile.phone==="+91 00000 00000"? "":data.profile.phone}
        onChange={v => onChange('data.profile.phone', v)}
      />

      <TextField
        className="mb-6"
        label={'Email Address'}
        placeholder="jane.doe@example.com"
        value={data.profile.email === "xxxxxxx@xxxx.com" ? "" : data.profile.email}
        onChange={v => onChange('data.profile.email', v)}
      />

      <TextField
        className="mb-6"
        label={'Linkedin'}
        placeholder="janedoe.me"
        value={data.profile.website==="https://www.linkedin.com/in/yourprofile"? "":data.profile.website}
        onChange={v => onChange('data.profile.website', v)}
      />

      <hr className="my-6" />

      <TextField
        className="mb-6"
        label={`Current Location`}
        placeholder="NY"
        value={data.profile.currentLocation==="Hyderabad"? "": data.profile.currentLocation}
        onChange={v => onChange('data.profile.currentLocation', v)}
      />

      <TextField
        className="mb-6"
        label={'ADDRESS LINE 1'}
        placeholder="Palladium Complex"
        value={data.profile.address.line1==="XXXXXXXXXXXXX"? "": data.profile.address.line1}
        onChange={v => onChange('data.profile.address.line1', v)}
      />

      <TextField
        className="mb-6"
        label={'ADDRESS LINE 2'}
        placeholder="140 E 14th St"
        value={data.profile.address.line2}
        onChange={v => onChange('data.profile.address.line2', v)}
      />

      <TextField
        className="mb-6"
        label={'ADDRESS LINE 3'}
        placeholder="New York, NY 10003 USA"
        value={data.profile.address.line3}
        onChange={v => onChange('data.profile.address.line3', v)}
      />
    </div>
  );
};

export default ProfileTab;
