import React, { useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { newUser } from '../../utils/index';
import firebase from '../../firestore/Firestore';
import AppContext from '../../context/AppContext';
import Google from './GoogleSignIn';

const LoginSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Required'),
    lastName: Yup.string()
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    password: Yup.string()
        .min(6, 'Password has to be atleast 6 characters long')
        .required('Required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords do not match')
        .required('Required'),
});


const SingUp = ({ signIn }) => {

    const context = useContext(AppContext);
    const { state, dispatch } = context;
    const db = firebase.firestore();

    const signUp = (e) => {
        dispatch({type: 'reset_complete'});
        dispatch({
            type: 'on_input',
            payload: {
                key: 'loading.state',
                value: true
            }
        })
        firebase.auth().createUserWithEmailAndPassword(e.email, e.password).then((user) => {
            db.collection("users").doc(user.user.uid).set({ firstName: e.firstName, lastName: e.lastName });

            newUser(dispatch, context)
            
        }).catch(function (error) {
            dispatch({
                type: 'on_input', payload: { key: 'loading.state', value: false }
            })
            var errorCode = error.code;
            var errormessage = error.message;
            // ...
            var errorMessage;
            if (errorCode === "auth/email-already-in-use") {
                errorMessage = "The email address is already in use by another account";
            } else {
                errorMessage = errormessage;
            }
            dispatch({
                type: 'on_input',
                payload: {
                    key: "errorMessage",
                    value: errorMessage
                }
            })
        });
    }

    return (
        <div className="">

            <div className="text-xl m-2 font-medium text-center lg:text-3xl">Create new Account</div>

            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    firstName: '',
                    lastName: '',
                    confirmPassword: '',
                }}
                validationSchema={LoginSchema}
                // validator={() => ({})}
                onSubmit={values => {
                    dispatch({ type: 'on_input', payload: { key: "errorMessage", value: null } });
                    signUp(values);
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className="w-full flex justify-center items-center">
                            <div className={`w-full flex flex-col my-2 mr-4`}>
                                <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2 lg:text-2xl">First Name</label>
                                <Field placeholder="Enter First Name" required name="firstName" type="firstName" className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl" />
                                {errors.firstName && touched.firstName ? <div className="text-red-600">{errors.firstName}</div> : null}
                            </div>

                            <div className={`w-full flex flex-col my-2 ml-4`}>
                                <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2 lg:text-2xl">Last Name</label>
                                <Field placeholder="Enter Last Name" required name="lastName" type="lastName" className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl" />
                                {errors.lastName && touched.lastName ? <div className="text-red-600">{errors.lastName}</div> : null}
                            </div>
                        </div>


                        <div className={`w-full flex flex-col my-2`}>
                            <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2 lg:text-2xl">Email</label>
                            <Field placeholder="Enter Email" required name="email" type="email" className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl" />
                            {errors.email && touched.email ? <div className="text-red-600">{errors.email}</div> : null}
                        </div>

                        <div className="w-full flex justify-center items-end">
                            <div className={`w-full flex flex-col my-2 mr-4`}>
                                <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2 lg:text-2xl">Password</label>
                                <Field placeholder="Enter Password" required name="password" type="password" className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl" />
                                {errors.password && touched.password ? <div className="text-red-600">{errors.password}</div> : null}
                            </div>

                            <div className={`w-full flex flex-col my-2 ml-4`}>
                                <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2 lg:text-2xl">Confirm Password</label>
                                <Field placeholder="Confirm Passowrd" type="password" required name="confirmPassword" className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl" />
                                {errors.confirmPassword && touched.confirmPassword ? <div className="text-red-600">{errors.confirmPassword}</div> : null}
                            </div>
                        </div>

                        {state.loading.state ?
                            <div className="font-semibold text-center">{state.loading.message}</div>
                            : <div className="text-red-700 font-semibold text-center">{state.errorMessage}</div>}

                        <div className="w-full flex justify-between items-center mb-4 mt-6">
                            <button disabled={state.loading.state} type="submit" value="submit" className="w-full px-5 py-2 bg-blue-600 shadow-md hover:shadow-2xl rounded-lg hover:bg-blue-800 inline-flex justify-center items-center">
                                <span className="text-white font-semibold lg:text-2xl">Sign Up</span>
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

            <Google message={'SignUp through Google'} />

            <button className="text-blue-600 font-semibold mt-6 lg:text-2xl" onClick={signIn}>Already have an Account?</button>

        </div>
    )
}

export default SingUp;