import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../context/AppContext';
import ReactMarkdown from 'react-markdown/with-html';
import { CSSTransition, TransitionGroup, } from 'react-transition-group';

var arr;

const Notification = () => {

    const context = useContext(AppContext);
    const { state } = context;

    state.notification.length === 0 ? arr = ['No notifications at the moment'] : arr = state.notification

    return (
        <div className="absolute right-0 border-2 shadow-2xl rounded bg-white overflow-scroll cursor-pointer" style={{ minWidth: "30rem", maxHeight: "40rem" }}>
            <div className="font-semibold text-xl lg:text-3xl px-4 py-2">Notifications</div>
            <hr className="" />
            <TransitionGroup>

                {arr.map((x, index) => (
                    <CSSTransition
                        key={index}
                        timeout={500}
                        classNames="item"
                    >
                        <div key={index} className="">
                            <ReactMarkdown className="rounded px-4 py-2 lg:text-3xl font-medium hover:bg-gray-300" escapeHtml={false} source={x} />
                            <hr className={`my-2 ${state.notification.length - 1 === index ? 'hidden' : null}`} />
                        </div>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </div>
    )
}

export default Notification;