import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import firebase from '../../firestore/Firestore';
import AppContext from '../../context/AppContext';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

const NewPassword = ({ goBack }) => {

    const context = useContext(AppContext);
    const { state } = context;
    const [notification, setNotification] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    const changePassword = (e) => {
        var auth = firebase.auth();
        var emailAddress = e.email;

        auth.sendPasswordResetEmail(emailAddress).then(function () {
            setErrorMessage(null);
            setNotification('Mail Sent, please check your mail');
        }).catch(function (error) {
            setNotification(null);
            if(error.message==="There is no user record corresponding to this identifier. The user may have been deleted."){
                setErrorMessage('No User with this email Id found, please check again')
            }else{
                setErrorMessage(error.message);
            }
        });
    }

    return (
        <div className="">

            <div className="text-xl m-4 font-medium text-center lg:text-3xl">Forgot Password</div>

            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={LoginSchema}
                // validator={() => ({})}
                onSubmit={values => {
                    changePassword(values);
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className={`w-full flex flex-col my-4`}>
                            <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2 lg:text-2xl">Email</label>
                            <Field required name="email" type="email" placeholder="Enter Email"
                                className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:text-3xl" />
                            {errors.email && touched.email ? <div className="text-red-600">{errors.email}</div> : null}
                        </div>


                            <div className="text-green-700 font-semibold text-center">{notification}</div>
                            <div className="text-red-700 font-semibold text-center">{errorMessage}</div>

                        <div className="w-full flex justify-between items-center mb-4 mt-6">
                            <button disabled={state.loading.state} type="submit" value="submit" className="w-full px-5 py-2 bg-blue-600 shadow-md hover:shadow-2xl rounded-lg hover:bg-blue-800 inline-flex justify-center items-center">
                                <span className="text-white font-semibold lg:text-2xl">Send Email</span>
                            </button>
                        </div>
                    </Form>
                )}

            </Formik>

            <button className="block align-left text-blue-600 font-semibold mt-6 lg:text-2xl" onClick={goBack}>Go back to SignIn Page</button>

        </div>
    )
}

export default NewPassword;